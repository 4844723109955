import { ClientReportingProgram } from '@types'

export const SET_CLIENT_REPORTING_PROGRAMS = 'SET_CLIENT_REPORTING_PROGRAMS'

export const setClientReportingPrograms = (
  clientReportingPrograms: ClientReportingProgram[],
) => ({
  type: SET_CLIENT_REPORTING_PROGRAMS,
  payload: clientReportingPrograms,
})
