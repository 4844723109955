import {
  TOGGLE_EDIT_ACCOUNT_MODAL,
  TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL,
  TOGGLE_EDIT_DINER_PROFILE_MODAL,
  TOGGLE_SEARCH_VIA_HUBSPOT_MODAL,
  TOGGLE_EDIT_CLIENT_REPORTING_PROGRAM_MODAL,
} from '@actions/ui/accountPage'

const initialEditAccountModalState = { show: false }
const initialEditAccountContactModalState = { show: false }
const initialEditDinerProfileModalState = { show: false }
const initialSearchViaHubSpotModalState = { show: false }
const initialEditClientReportingProgramModalState = { show: false }

export const editAccountModal = (
  state = initialEditAccountModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        show: action.show,
      }
    default:
      return state
  }
}

export const editAccountContactModal = (
  state = initialEditAccountContactModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}

export const editDinerProfileModal = (
  state = initialEditDinerProfileModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_DINER_PROFILE_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}
export const searchViaHubSpotModal = (
  state = initialSearchViaHubSpotModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_SEARCH_VIA_HUBSPOT_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}
export const editClientReportingProgramModal = (
  state = initialEditClientReportingProgramModalState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_EDIT_CLIENT_REPORTING_PROGRAM_MODAL:
      return {
        show: action.show,
      }
    default:
      return state
  }
}
