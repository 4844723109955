import React from 'react'
import Moment from 'moment-timezone'
import { AutocompleteInput } from '@components/common/form'
import AIChatBubble from '@res/images/ai-chat-bubble.svg'
import XSpacing from '@components/common/XSpacing'
import TooltipModal from '@components/common/modal/TooltipModal'
import { ApiDinerProfile, ClientReportingProgram } from '@types'
import { YSpacing } from '@components/common'

interface DinerProfilesSectionProps {
  account?: { id: string }
  width?: string
  profile?: ApiDinerProfile
  reportingProgram?: ClientReportingProgram
  removedProgramReason?: string | null
  loadDinerProfiles: (
    params: Record<string, string | number>,
  ) => Promise<ApiDinerProfile[]>
  loadClientReportingPrograms: (
    params: Record<string, string | number | boolean>,
  ) => Promise<ClientReportingProgram[]>
  onSelectProfile: (profile: ApiDinerProfile) => void
  onSelectClientReportingProgram: (
    reportingProgram: ClientReportingProgram,
  ) => void
  onClearDinerProfile: () => void
  onClearClientReportingProgram: () => void
}

const DinerProfilesSection: React.FC<DinerProfilesSectionProps> = ({
  account,
  width,
  profile,
  reportingProgram,
  removedProgramReason,

  loadDinerProfiles,
  loadClientReportingPrograms,
  onSelectProfile,
  onSelectClientReportingProgram,
  onClearDinerProfile,
  onClearClientReportingProgram,
}) => {
  if (!account) {
    return null
  }

  const loadProfiles = async ({ search }: { search: string }) => {
    const profiles = await loadDinerProfiles({
      accountId: account.id,
      search,
    })

    if (!profile && profiles.find((p) => p.isDefault)) {
      onSelectProfile(profiles.find((p) => p.isDefault) as ApiDinerProfile)
    }

    return profiles
  }

  const loadPrograms = async ({ search }: { search: string }) => {
    return loadClientReportingPrograms({
      clientId: account.id,
      disabled: false,
      name: search,
    })
  }

  const renderProfileOption = (profile: ApiDinerProfile) => (
    <div
      key={profile.id}
      className="cursor-pointer hover:bg-gray-200 p-2"
      onClick={() => onSelectProfile(profile)}
    >
      <span className="font-semibold">{profile.name}</span>{' '}
      {`[Created ${Moment(profile.createdAt).format('MM/DD/YYYY')}]`}
    </div>
  )

  return (
    <div style={{ width }}>
      <AutocompleteInput
        label="Search Diner Profiles"
        value={profile?.name || ''}
        loaderFunction={loadProfiles}
        optionRenderFunction={renderProfileOption}
        onClear={onClearDinerProfile}
        renderAdditionalLabels={() => (
          <>
            <>
              <XSpacing width="5px" />
              <img
                alt="AI Chat Bubble"
                style={{ height: '20px' }}
                src={AIChatBubble}
              />
              <XSpacing width="5px" />
            </>
            <TooltipModal
              unicode="&#9432;"
              width="400px"
              marginTop="20px"
              information={
                'After creating diner profiles in Client Profiles, you can autopopulate event details like time, headcount, address, and contacts. You can also search past orders to generate bulk proposals, with or without menu items.'
              }
            />
          </>
        )}
      />
      <YSpacing height="10px" />
      <AutocompleteInput
        label="Search Reporting Programs"
        value={reportingProgram?.name || ''}
        loaderFunction={loadPrograms}
        onSelect={onSelectClientReportingProgram}
        onClear={onClearClientReportingProgram}
      />
      {removedProgramReason && (
        <>
          <YSpacing height="10px" />
          Removed Reporting Program:{' '}
          <span className="text-red-500">{removedProgramReason}</span>
        </>
      )}
    </div>
  )
}

export default DinerProfilesSection
