import * as accountPageUIActions from './accountPage'
import * as accountsReceivableUIActions from './accountsReceivable'
import * as alertUIActions from './alert'
import * as auditHistoryUIActions from './auditHistory'
import * as batchOrderPageUIActions from './batchOrderPage'
import * as calcChefProcFeeModalUIActions from './calcChefProcFeeModal'
import * as chefPageUIActions from './chefPage'
import * as confirmationUIActions from './confirmation'
import * as dashboardUIActions from './dashboard'
import * as errorsUIActions from './errors'
import * as flashMessageUIActions from './flashMessage'
import * as infoModalActions from './infoModal'
import * as resultsMessageModalUIActions from './resultsMessageModal'
import * as loadingIndicatorUIActions from './loadingIndicator'
import * as marketPageUIActions from './marketPage'
import * as orderPageUIActions from './orderPage'
import * as proposalDashboardUIActions from './proposalDashboard'
import * as proposalPageUIActions from './proposalPage'
import * as userPageUIActions from './userPage'

export default {
  ...accountPageUIActions,
  ...accountsReceivableUIActions,
  ...alertUIActions,
  ...auditHistoryUIActions,
  ...batchOrderPageUIActions,
  ...calcChefProcFeeModalUIActions,
  ...chefPageUIActions,
  ...confirmationUIActions,
  ...dashboardUIActions,
  ...errorsUIActions,
  ...flashMessageUIActions,
  ...infoModalActions,
  ...resultsMessageModalUIActions,
  ...loadingIndicatorUIActions,
  ...marketPageUIActions,
  ...orderPageUIActions,
  ...proposalDashboardUIActions,
  ...proposalPageUIActions,
  ...userPageUIActions,
}
