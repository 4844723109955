/*
Not great to have all service imports in a separate file, but is a workaround for
testing avoiding importing browserHistory node module
*/

import { initServices as initCoreServices } from 'hungry-core2'

import * as accountServices from './account'
import * as accountsReceivableServices from './accountsReceivable'
import * as aiServices from './aiApi'
import * as adminUserServices from './adminUser'
import * as batchOrderServices from './batchOrder'
import * as braintreeServices from './braintree'
import * as chefServices from './chef'
import * as coreServices from './core'
import * as dashboardServices from './dashboard'
import * as fakeServices from './fake'
import * as groupOrdersServices from './groupOrders'
import * as headquarterServices from './headquarter'
import * as hubspotServices from './hubspot'
import * as invoiceServices from './invoice'
import * as marketServices from './market'
import * as menuCardServices from './menuCards'
import * as mlbOrderServices from './mlbOrder'
import * as nodeApiServices from './nodeApi'
import * as orderServices from './order'
import * as promoCodesService from './promoCodes'
import * as proposalsService from './proposal'
import * as popUpServices from './popUp'
import * as recurringPopUpServices from './recurringPopUp'
import * as popUpDiscountServices from './popUpDiscount'
import * as popUpClientServices from './popUpClient'
import * as popUpCustomerServices from './popUpCustomer'
import * as receiptServices from './receipt'
import * as referralServices from './referral'
import * as routerServices from './router'
import * as salesStatsServices from './salesStats'
import * as serviceVendorServices from './serviceVendor'
import * as settingsServices from './settings'
import * as subscriptionServices from './subscription'
import * as supplyServices from './supply'
import * as surveyServices from './survey'
import * as vxServices from './virtualExperience'
import * as vxInvoiceServices from './vxInvoice'
import UIService from './ui/index'
import * as validationServices from './validation/index'

const services = {
  ...accountServices,
  ...accountsReceivableServices,
  ...aiServices,
  ...adminUserServices,
  ...batchOrderServices,
  ...braintreeServices,
  ...chefServices,
  ...coreServices,
  ...dashboardServices,
  ...fakeServices,
  ...groupOrdersServices,
  ...headquarterServices,
  ...hubspotServices,
  ...invoiceServices,
  ...marketServices,
  ...menuCardServices,
  ...mlbOrderServices,
  ...nodeApiServices,
  ...orderServices,
  ...popUpServices,
  ...recurringPopUpServices,
  ...popUpClientServices,
  ...popUpCustomerServices,
  ...popUpDiscountServices,
  ...promoCodesService,
  ...proposalsService,
  ...receiptServices,
  ...referralServices,
  ...routerServices,
  ...salesStatsServices,
  ...serviceVendorServices,
  ...settingsServices,
  ...subscriptionServices,
  ...supplyServices,
  ...surveyServices,
  ...vxServices,
  ...vxInvoiceServices,
  UIService,
  ...validationServices,
}

export const initServices = (store) => {
  return initCoreServices(store, services)
}
