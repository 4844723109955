import React, { Component, Fragment } from 'react'
import Moment from 'moment'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { Dropdown, LinkText, CurrencyInput } from '@components/common/form'
import DividerLine from '@components/common/DividerLine'
import {
  colors,
  DEFAULT_CLEANUP_FEE,
  HolidayDates,
  HUNGRY_CARBON_NEUTRAL_CONTRIBUTION,
} from '../../../../../constants'
import { Label } from '@res/styledComponents/index'
import { AuthorizedInteractable } from '@containers/common/auth'
import { taxPercentString, roundMoneyUp } from '~/utils'
import trash from '@res/images/bin.svg'

const PctDeliveryFeeCutoff = Moment('2022-09-10', 'YYYY-MM-DD')

const RefreshingAttributes = [
  'alcoholTaxRates',
  'carbonNeutral',
  'carbonNeutralContribution',
  'chefs',
  'cleanupFee',
  'clientSetUpTime',
  'deliveryFee',
  'deliveryTaxRates',
  'discountAmount',
  'discountReason',
  'discountType',
  'discountKind',
  'discounts',
  'isDeliveryFeeOverride',
  'isGratuityOverride',
  'isStaffingFeeOverride',
  'isFreeDelivery',
  'isTaxExempt',
  'isPaid',
  'isVCX',
  'isMounted',
  'needsCleanup',
  'needsStaffing',
  'numberOfStaff',
  'orderType',
  'predictedServiceCosts',
  'serviceType',
  'servicesTaxRates',
  'staffingFee',
  'staffingHours',
  'staffingRate',
  'supplyBreakdown',
  'tax',
  'taxRates',
  'taxExemptCause',
  'tip',
]

const AttributeDefaults = {
  alcoholTaxRates: [],
  cleanupFee: 0,
  discountAmount: 0,
  discountReason: '',
  discountType: '',
  discountKind: '',
  discounts: [],
  deliveryTaxRates: [],
  isDeliveryFeeOverride: false,
  isGratuityOverride: false,
  isStaffingFeeOverride: false,
  isMounted: true,
  needsCleanup: false,
  needsStaffing: false,
  numberOfStaff: 0,
  predictedServiceCosts: 0,
  servicesTaxRates: [],
  staffingFee: 0,
  staffingHours: 0,
  staffingRate: 0,
  tax: 0,
  taxRates: [],
  orderSettings: {},
}

const FreeOrderTypes = ['Tasting', 'Hungry HQ Tasting', 'Comped']

const DiscountTypes = [
  {
    value: 'flat',
    label: 'Flat',
  },
  {
    value: 'percent',
    label: 'Percent',
  },
]

const DiscountKinds = [
  {
    value: 'cancellation',
    label: 'Cancellation',
  },
  {
    value: 'discount',
    label: 'Discount',
  },
  {
    value: 'hungry_guarantee',
    label: 'Hungry Guarantee',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'tasting',
    label: 'Tasting',
  },
  {
    value: 'partnership',
    label: 'Partnership',
  },
  {
    value: 'budget',
    label: 'Budget',
  },
]

const DiscountReasons = [
  {
    value: 'late_delivery',
    label: 'Late Delivery',
  },
  {
    value: 'food_quality_issue',
    label: 'Food Quality Issue',
  },
  {
    value: 'food_portion_issue',
    label: 'Food Portion Issue',
  },
  {
    value: 'captain_service_issue',
    label: 'Captain/Service Issue',
  },
  {
    value: 'chef_declined_order',
    label: 'Chef Declined Order',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'tastings',
    label: 'Tastings',
  },
  {
    value: 'surprise_and_delight',
    label: 'Surprise & Delight',
  },
  {
    value: 'by_client',
    label: 'By Client',
  },
  {
    value: 'vx_deposit',
    label: 'VX Deposit',
  },
]

const PartnershipReasons = [
  {
    value: 'foodworks',
    label: 'Foodworks',
  },
  {
    value: 'garten',
    label: 'Garten',
  },
  {
    value: 'roaming_hunger',
    label: 'Roaming Hunger',
  },
  {
    value: 'other',
    label: 'Other',
  },
]

const BudgetReasons = [
  {
    value: 'low_budget',
    label: 'Low Budget',
  },
  {
    value: 'trial',
    label: 'Trial',
  },
  {
    value: 'vip_exemption',
    label: 'VIP Exemption',
  },
]

class TotalSection extends Component {
  state = {
    alcoholTaxRates: [],
    // cannot detect subtotal changes because chefs is an object that is set in state by reference so
    // componentWillReceiveProps cant detect subtotal changes so need to explicitly set in state
    calculatedSubtotal: 0,
    carbonNeutral: false,
    carbonNeutralContribution: 0,
    // only want to run this once without relying on mount lifecycle
    hasCalculatedTaxRates: false,
    chefs: [],
    cleanupFee: 0,
    clientSetUpTime: undefined,
    deliveryFee: 0,
    deliveryTaxRates: [],
    deliveryFeePercent: undefined,
    deliveryFeeLimit: undefined,
    discountAmount: 0,
    discountReason: '',
    discountKind: '',
    discountType: '',
    discounts: [],
    isDeliveryFeeOverride: false,
    isGratuityOverride: false,
    isStaffingFeeOverride: false,
    isFreeDelivery: false,
    isTaxExempt: false,
    isPaid: false,
    isVCX: false,
    isMounted: false,
    isPredicting: false,
    isServiceable: false,
    needsCleanup: false,
    needsStaffing: false,
    numberOfStaff: 0,
    orderType: '',
    predictedServiceCosts: 0,
    routingInputsChanged: false,
    serviceType: 'Regular',
    servicesTaxRates: [],
    staffingFee: 0,
    staffingHours: 0,
    staffingRate: 0,
    serviceFee: 0,
    serviceInputsChanged: false,
    showServiceCostBreakdown: false,
    supplyBreakdown: undefined,
    suppliesCost: 0,
    taxExemptCause: '',
    taxRates: [],
    tip: 0,
  }
  isCalcTaxRates = false
  isCalcTaxRatesWithTax = false
  isPredictingServiceCosts = false

  componentDidMount() {
    const {
      supplyBreakdown,
      taxRecord,
      discount,
      isTaxExempt,
      orderId,
      serviceInputsChanged,
      routingInputsChanged,
    } = this.props
    const newState = {}
    RefreshingAttributes.forEach((k) => {
      newState[k] = this.props[k] == null ? AttributeDefaults[k] : this.props[k]
    })
    newState.suppliesCost = (supplyBreakdown && supplyBreakdown.total.cost) || 0
    newState.supplyBreakdown = supplyBreakdown

    if (!orderId || serviceInputsChanged) {
      newState.serviceInputsChanged = true
    }
    if (!orderId || routingInputsChanged) {
      newState.routingInputsChanged = true
    }

    if (discount) {
      newState.discountAmount = discount.amount || 0
      newState.discountReason = discount.reason || ''
      newState.discountKind = discount.kind || ''
      newState.discountType = discount.type || ''
    }

    if (this.hasNoTaxRates() && !isTaxExempt) {
      const noPrevTaxCalc = !taxRecord || !taxRecord.zipcode
      this.calculateTaxRates(noPrevTaxCalc)
    }

    this.setState(newState, () => {
      this.onNextPropsSetStateCallback({
        lastDeliveryFeeLimit: 0,
        lastDeliveryFeePercent: 0,
        lastDefaultTipPercent: 0,
        lastSubtotal: 0,
        needsCleanupChanged: true,
        needsStaffingChanged: true,
        setupTimeChanged: true,
      })
      this.onPredictServiceCosts()
    })

    if (newState.serviceInputsChanged || newState.routingInputsChanged) {
      this.props.onChange({
        serviceInputsChanged: false,
        routingInputsChanged: false,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = {}
    RefreshingAttributes.forEach((k) => {
      if (nextProps[k] !== this.props[k]) {
        newState[k] = nextProps[k] == null ? AttributeDefaults[k] : nextProps[k]
      }
    })

    const {
      clientSetUpTime,
      discount,
      dropoffAddress,
      headCount,
      isPaid,
      isTaxExempt,
      serviceType,
      needsCleanup,
      needsStaffing,
      numChefs,
      supplyBreakdown,
      taxRecord,
    } = nextProps

    // set supplybreakdown
    if (supplyBreakdown != this.props.supplyBreakdown) {
      newState.suppliesCost =
        (supplyBreakdown && supplyBreakdown.total.cost) || 0
      newState.supplyBreakdown = supplyBreakdown
    }

    // detect change to service inputs
    if (
      headCount !== this.props.headCount ||
      dropoffAddress !== this.props.dropoffAddress ||
      numChefs !== this.props.numChefs ||
      serviceType !== this.props.serviceType ||
      needsCleanup !== this.props.needsCleanup ||
      (clientSetUpTime &&
        this.props.clientSetUpTime &&
        clientSetUpTime !== this.props.clientSetUpTime)
    ) {
      newState.serviceInputsChanged = true
    }

    // detect change to routing inputs
    if (
      (headCount !== this.props.headCount && this.props.headCount) ||
      (dropoffAddress !== this.props.dropoffAddress &&
        this.props.dropoffAddress) ||
      (numChefs !== this.props.numChefs && this.props.numChefs) ||
      (clientSetUpTime &&
        this.props.clientSetUpTime &&
        clientSetUpTime !== this.props.clientSetUpTime)
    ) {
      newState.routingInputsChanged = true
    }
    if (dropoffAddress && numChefs && headCount) {
      newState.isServiceable = true
    } else {
      newState.isServiceable = false
    }

    if (discount !== this.props.discount) {
      newState.discountAmount = discount.amount || 0
      newState.discountReason = discount.reason || ''
      newState.discountKind = discount.kind || ''
      newState.discountType = discount.type || ''
    }

    const setupTimeChanged = clientSetUpTime !== this.props.clientSetUpTime
    const needsCleanupChanged = this.props.needsCleanup !== needsCleanup
    const needsStaffingChanged = this.props.needsStaffing !== needsStaffing
    const lastSubtotal = this.state.calculatedSubtotal
    const lastDefaultTipPercent = this.props.defaultTipPercent
    const lastDeliveryFeePercent = this.props.deliveryFeePercent
    const lastDeliveryFeeLimit = this.props.deliveryFeeLimit

    this.setState(newState, () => {
      this.onNextPropsSetStateCallback({
        lastDeliveryFeeLimit,
        lastDeliveryFeePercent,
        lastDefaultTipPercent,
        lastSubtotal,
        needsCleanupChanged,
        needsStaffingChanged,
        setupTimeChanged,
      })
      this.onPredictServiceCosts()
    })

    const zipcodeChanged =
      taxRecord && dropoffAddress && taxRecord.zipcode !== dropoffAddress.zip
    // only want to check this once in case this isnt run on mount since a legacy bug has left some legacy
    // proposals and orders without tax rates and sometimes there are no tax rates for a zip so cant rely
    // on present of rates for a successful calculation
    const isMissingTaxRates =
      this.hasNoTaxRates() && !isTaxExempt && !this.state.hasCalculatedTaxRates

    // recalc tax rates on zip code change
    // if order is already paid then tax already reports so only recalc tax rates if taxable balances change too
    if (
      isMissingTaxRates ||
      (zipcodeChanged && (this.getTaxableBalsChanged() || !isPaid))
    ) {
      this.calculateTaxRates(true)
    }
  }

  // ideally all the automatically calculated balances should be defined here
  onNextPropsSetStateCallback = ({
    lastDeliveryFeeLimit,
    lastDeliveryFeePercent,
    lastDefaultTipPercent,
    lastSubtotal,
    needsCleanupChanged,
    needsStaffingChanged,
    setupTimeChanged,
  }) => {
    const {
      cleanupFee,
      createdAt,
      isDeliveryFeeOverride,
      isGratuityOverride,
      isVCX,
      needsCleanup,
      defaultTipPercent,
      deliveryFeePercent,
      deliveryFeeLimit,
    } = this.props

    // auto gen delivery fee
    const nextSubtotal = this.calculateSubtotal()
    if (
      (nextSubtotal !== lastSubtotal ||
        setupTimeChanged ||
        lastDeliveryFeePercent !== deliveryFeePercent ||
        lastDeliveryFeeLimit !== deliveryFeeLimit) && // dependencies change
      (!createdAt || Moment(createdAt).isAfter(PctDeliveryFeeCutoff)) && // after new delivery fee deployment
      !isDeliveryFeeOverride && // not a manual override
      !isVCX // not a vcx order
    ) {
      this.onCalcDeliveryFee({ subtotal: nextSubtotal })
    }

    // calculate tip
    if (
      !isGratuityOverride &&
      defaultTipPercent &&
      (nextSubtotal !== lastSubtotal ||
        lastDefaultTipPercent !== defaultTipPercent) &&
      !!nextSubtotal
    ) {
      const tip = this.props.calculateTip({
        percent: defaultTipPercent,
        total: nextSubtotal,
      })
      this.onChangeCurrency('tip')(tip)
    }

    // calculate service fees
    if (!needsCleanup && cleanupFee > 0) {
      this.onChangeServiceFee('cleanupFee')(0.0)
    } else if (needsCleanup && needsCleanupChanged && !cleanupFee) {
      this.onChangeServiceFee('cleanupFee')(DEFAULT_CLEANUP_FEE)
    }
    if (needsStaffingChanged) {
      this.updateServiceFee()
    }

    this.setState({ calculatedSubtotal: nextSubtotal })
  }

  onChange = (attribute) => (e) => {
    const { onChange } = this.props
    const newState = { [attribute]: e.target.value }

    this.setState(newState)
    onChange && onChange(newState)
  }

  onChangeCurrency = (attribute) => (value) => {
    const { onChange } = this.props
    const newState = { [attribute]: value }

    this.setState(newState)
    onChange && onChange(newState)
  }

  onChangeCarbonNeutralContrib = (value) => {
    const { onChange } = this.props
    let newState
    if (value >= HUNGRY_CARBON_NEUTRAL_CONTRIBUTION) {
      newState = {
        carbonNeutralContribution: HUNGRY_CARBON_NEUTRAL_CONTRIBUTION,
      }
    } else {
      newState = { carbonNeutralContribution: value }
    }

    this.setState(newState)
    onChange && onChange(newState)
  }

  onChangeDiscount = ({ amount, reason, kind, type }) => {
    const { discount, onChange } = this.props
    let { discountAmount, discountType, discountReason, discountKind } =
      this.state
    discountAmount = amount != undefined ? amount : discountAmount
    discountType = type != undefined ? type : discountType
    discountReason = reason ? reason : discountReason
    discountKind = kind ? kind : discountKind
    if (discountKind === 'tasting') {
      discountType = 'percent'
      discountAmount = '100'
    }
    this.setState({
      discountAmount,
      discountType,
      discountReason,
      discountKind,
    })
    if (onChange) {
      if (type === '' || reason === '' || kind === '') {
        if (discount) {
          onChange({ discount: { id: discount.id, _destroy: true } })
        } else {
          onChange({ discount: null })
        }
      } else {
        onChange({
          discount: {
            amount: discountAmount,
            id: discount && discount.id,
            reason: discountReason,
            kind: discountKind,
            type: discountType,
          },
        })
      }
    }
  }

  onRemoveMPDiscount = (discount) => () => {
    const { discounts } = this.props
    const index = discounts.findIndex((d) => d.id === discount.id)
    if (index < 0) {
      return
    }
    const nextDiscount = { ...discount, _destroy: true }
    const nextDiscounts = [...discounts]
    nextDiscounts.splice(index, 1, nextDiscount)
    this.props.onChange({ discounts: nextDiscounts })
  }

  onChangeIsTaxExempt = (e) => {
    const { checked } = e.target

    this.setState({ isTaxExempt: checked })
    this.props.onChange({ isTaxExempt: checked })
  }

  onChangeIsDeliveryFeeOverride = (e) => {
    const { checked } = e.target

    this.setState({ isDeliveryFeeOverride: checked }, this.updateServiceFee)
    this.props.onChange({ isDeliveryFeeOverride: checked })
  }

  onChangeIsGratuityOverride = (e) => {
    const {
      orderSettings: { tip, defaultTipPercent },
    } = this.props
    const { checked } = e.target

    const nextState = { isGratuityOverride: checked }
    if (!checked) {
      if (tip) {
        this.onChangeCurrency('tip')(tip)
      } else if (defaultTipPercent) {
        this.onChangeCurrency('tip')(
          this.props.calculateTip({
            percent: defaultTipPercent,
            total: this.calculateSubtotal(),
          }),
        )
      }
    }

    this.setState(nextState)
    this.props.onChange(nextState)
  }

  onChangeIsStaffingFeeOverride = (e) => {
    const {
      orderSettings: {
        needsStaffing,
        numberOfStaff,
        staffingHours,
        staffingRate,
      },
    } = this.props
    const { checked } = e.target

    const nextState = { isStaffingFeeOverride: checked }
    if (!checked) {
      if (needsStaffing) {
        this.onChangeServiceFee('numberOfStaff')(numberOfStaff)
        this.onChangeServiceFee('staffingHours')(staffingHours)
        this.onChangeServiceFee('staffingRate')(staffingRate)
      }
    }

    this.setState(nextState)
    this.props.onChange(nextState)
  }

  renderTipInfo = () => {
    const {
      orderSettings: { tip, defaultTipPercent, tipSettingType },
      isGratuityOverride,
    } = this.props

    if (isGratuityOverride || (!tip && !defaultTipPercent)) {
      return null
    }

    let tipInfo = 'Applying'
    if (tipSettingType) {
      tipInfo = `Applying ${tipSettingType}`
    }
    if (tip) {
      tipInfo = `${tipInfo} default $${tip} gratuity`
    } else if (defaultTipPercent) {
      tipInfo = `${tipInfo}${
        !tipSettingType ? ' global' : ''
      } default ${defaultTipPercent}% gratuity`
    }

    return <p className="font-semibold">{tipInfo}</p>
  }

  updateServiceFee = () => {
    const {
      deliveryFeePercent,
      deliveryFeeLimit,
      calculateServiceFee,
      calculateDeliveryFee,
      calculateStaffingFee,
      needsStaffing,
      onChange,
    } = this.props
    const {
      clientSetUpTime,
      deliveryFee: stateDeliveryFee,
      cleanupFee,
      isDeliveryFeeOverride,
      isVCX,
      numberOfStaff,
      staffingHours,
      staffingRate,
    } = this.state

    const subtotal = this.calculateSubtotal()
    const serviceFee = calculateServiceFee({
      needsStaffing,
      isDeliveryFeeOverride,
      isVCX,
      subtotal,
      cleanupFee,
      deliveryFee: stateDeliveryFee,
      numberOfStaff,
      staffingHours,
      deliveryFeePercent,
      deliveryFeeLimit,
      staffingRate,
    })
    const deliveryFee = calculateDeliveryFee({
      clientSetUpTime,
      isDeliveryFeeOverride,
      isVCX,
      subtotal,
      deliveryFee: stateDeliveryFee,
      percent: deliveryFeePercent,
      limit: deliveryFeeLimit,
    })
    const staffingFee = calculateStaffingFee({
      numberOfStaff,
      staffingHours,
      staffingRate,
      needsStaffing,
    })

    this.setState({
      serviceFee,
      deliveryFee,
      cleanupFee,
      staffingFee,
      numberOfStaff,
      staffingHours,
    })
    onChange({
      didEditServiceFee: true,
      serviceFee,
      deliveryFee,
      cleanupFee,
      needsCleanup: !cleanupFee ? false : true,
      staffingFee,
      numberOfStaff,
      staffingHours,
      staffingRate,
      isFreeDelivery: deliveryFee === 0,
    })
  }

  onChangeServiceFee = (feeKey) => (value) => {
    this.setState({ [feeKey]: value }, this.updateServiceFee)
  }

  onChangeCleanupFee = (cleanupFee) => {
    const isAdmin = this.props.userPermissions.some(
      (permission) => permission === 'admin' || permission === 'finance',
    )

    if (cleanupFee < DEFAULT_CLEANUP_FEE && !isAdmin) {
      this.props.displayFailureMessage(
        `Cleanup fee must be greater than or equal to $${DEFAULT_CLEANUP_FEE}`,
      )
      this.onChangeServiceFee('cleanupFee')(DEFAULT_CLEANUP_FEE)

      return
    }
    this.onChangeServiceFee('cleanupFee')(cleanupFee)
  }

  onCalcDeliveryFee = ({ subtotal }) => {
    const {
      clientSetUpTime,
      isDeliveryFeeOverride,
      isVCX,
      deliveryFee: stateDeliveryFee,
    } = this.state
    const { deliveryFeePercent, deliveryFeeLimit } = this.props

    const deliveryFee = this.props.calculateDeliveryFee({
      clientSetUpTime,
      isDeliveryFeeOverride,
      isVCX,
      subtotal,
      deliveryFee: stateDeliveryFee,
      percent: deliveryFeePercent,
      limit: deliveryFeeLimit,
    })
    this.onChangeServiceFee('deliveryFee')(deliveryFee)
  }

  onPredictServiceCosts = async (force = false) => {
    const { isServiceable, routingInputsChanged, serviceInputsChanged } =
      this.state
    if (
      this.isPredictingServiceCosts ||
      (!force && (!serviceInputsChanged || !routingInputsChanged))
    ) {
      return
    }
    this.isPredictingServiceCosts = true
    this.setState({ isPredicting: true })
    if (isServiceable) {
      const updated =
        await this.props.updateServiceAndSupplyCosts(routingInputsChanged)
      if (updated) {
        this.setState({
          serviceInputsChanged: false,
          routingInputsChanged: false,
        })
      }
    } else if (!isServiceable && force) {
      this.props.displayFailureMessage(
        'Order is missing service details so cannot predict service costs',
      )
    }
    this.setState({ isPredicting: false })
    this.isPredictingServiceCosts = false
  }

  onInput = (field) => (value) => {
    if (value.target) {
      ;({ value } = value.target)
    }
    this.setState({ [field]: value }, () => {
      this.props.onChange({ [field]: value })
    })
  }

  calculateChefAmount = () => {
    const { adjustedChefPayouts, calculateChefAmount } = this.props
    const { chefs } = this.state
    if (!chefs || chefs.length === 0) {
      return 0
    }

    return calculateChefAmount({ adjustedChefPayouts, chefs })
  }

  calculatePreTaxTotal = () => {
    const { deliveryFeePercent, deliveryFeeLimit, needsStaffing } = this.props
    const subtotal = this.calculateSubtotal()

    return (
      this.props.calculatePreTaxTotal({
        ...this.state,
        deliveryFeePercent,
        deliveryFeeLimit,
        needsStaffing,
        subtotal,
      }) || 0
    )
  }

  calculateServiceFee = () => {
    const {
      calculateServiceFee,
      deliveryFeePercent,
      deliveryFeeLimit,
      needsStaffing,
      orderType,
    } = this.props
    const { serviceFee } = this.state

    if (FreeOrderTypes.includes(orderType)) {
      return 0
    }
    /* if have saved or edited non-zero service fee */
    if (serviceFee) {
      return serviceFee || 0
    }
    const subtotal = this.calculateSubtotal()

    return (
      calculateServiceFee({
        ...this.state,
        deliveryFeePercent,
        deliveryFeeLimit,
        needsStaffing,
        subtotal,
      }) || 0
    )
  }

  calculateSubtotal = () => {
    const { calculateSubtotal, orderType } = this.props
    if (FreeOrderTypes.includes(orderType)) {
      return 0
    }

    return calculateSubtotal(this.state) || 0
  }

  calculateSubtotalPreTaxPricePerHead = () => {
    const { headCount } = this.props
    const subtotal = this.calculateSubtotal()

    return headCount ? roundMoneyUp(subtotal / headCount) : 0
  }

  calculateTotalPostTaxPricePerHead = () => {
    const { headCount } = this.props
    const total = this.calculateTotal()

    if (headCount && total) {
      return total / headCount
    }

    return 0
  }

  calculateTax = (force = false) => {
    const {
      calculateTax,
      orderType,
      tax,
      deliveryFeePercent,
      deliveryFeeLimit,
      needsStaffing,
    } = this.props
    if (FreeOrderTypes.includes(orderType)) {
      return 0
    }
    if (!this.getTaxableBalsChanged() && !force) {
      return tax || 0
    }
    const subtotal = this.calculateSubtotal()

    return (
      calculateTax({
        ...this.state,
        deliveryFeePercent,
        deliveryFeeLimit,
        needsStaffing,
        subtotal,
      }) || 0
    )
  }

  calculateTip = (percent) => {
    if (FreeOrderTypes.includes(this.props.orderType)) {
      return 0
    }
    const total = this.calculateSubtotal()

    return this.props.calculateTip({ percent, total }) || 0
  }

  calculateTotal = () => {
    let { total } = this.props
    const { orderType } = this.props

    let tax
    if (FreeOrderTypes.includes(orderType)) {
      total = 0
      tax = 0
    } else {
      total = this.calculatePreTaxTotal()
      tax = this.calculateTax()
    }
    const { tip = 0, discounts } = this.state

    return this.props.calculateTotal({ tax, tip, total, discounts }) || 0
  }

  calculateGrossMargin = () => {
    const {
      tip = 0,
      chefs,
      predictedServiceCosts,
      supplyBreakdown,
      carbonNeutralContribution,
    } = this.state
    const total = this.calculateTotal()
    const tax = this.calculateTax()
    const suppliesCost = (supplyBreakdown && supplyBreakdown.total.cost) || 0

    return (
      this.props.calculateGrossMargin({
        adjustedChefPayouts: this.props.adjustedChefPayouts,
        chefs,
        tip,
        total,
        tax,
        predictedServiceCosts,
        suppliesCost,
        carbonNeutralContribution,
      }) || 0
    )
  }

  calculateTaxRates = async (doRecalcTax = true) => {
    // already calculating taxes so return
    if (
      (!doRecalcTax && this.isCalcTaxRates) ||
      (doRecalcTax && this.isCalcTaxRatesWithTax) ||
      !this.props.zipcode
    ) {
      return
    }
    if (!doRecalcTax) {
      this.isCalcTaxRates = true
    }
    if (doRecalcTax) {
      this.isCalcTaxRatesWithTax = true
    }

    const { getTaxRates, zipcode, tax } = this.props

    const taxRates = await getTaxRates(zipcode)
    if (taxRates && Object.keys(taxRates).length !== 0) {
      this.setState({ ...taxRates }, () => {
        const orderUpdates = { ...taxRates }
        const taxRecord = { ...taxRates, zipcode, tax }
        if (doRecalcTax) {
          orderUpdates.tax = this.calculateTax(true)
          taxRecord.tax = orderUpdates.tax
        }
        orderUpdates.taxRecord = taxRecord
        this.props.onChange(orderUpdates)
      })
    }
    this.setState({ hasCalculatedTaxRates: true })

    if (!doRecalcTax) {
      this.isCalcTaxRates = false
    } else {
      this.isCalcTaxRatesWithTax = false
    }
  }

  hasNoTaxRates = () => {
    const { taxRates, deliveryTaxRates, servicesTaxRates, alcoholTaxRates } =
      this.props

    return (
      [
        ...(taxRates || []),
        ...(deliveryTaxRates || []),
        ...(servicesTaxRates || []),
        ...(alcoholTaxRates || []),
      ].length <= 0
    )
  }

  getTotalFoodTaxRate = () => {
    const { taxRates } = this.props

    return taxPercentString(taxRates)
  }

  getTotalDeliveryTaxRate = () => {
    const { deliveryTaxRates } = this.props

    return taxPercentString(deliveryTaxRates)
  }

  getTotalServicesTaxRate = () => {
    const { servicesTaxRates } = this.props

    return taxPercentString(servicesTaxRates)
  }

  getTotalAlcoholTaxRate = () => {
    const { alcoholTaxRates } = this.props

    return taxPercentString(alcoholTaxRates)
  }

  getTaxableBalsChanged = () => {
    if (!this.state.isMounted) {
      return false
    }
    const { initialBalances } = this.props

    if (!initialBalances) {
      return true
    }

    const { subtotal, serviceFee, discounts } = initialBalances
    const nextSubtotal = this.calculateSubtotal()
    const nextServiceFee = this.calculateServiceFee()
    const nextDiscounts = this.props.calculateDiscounts({
      subtotal: nextSubtotal,
      discounts: this.state.discounts,
      discountAmount: this.state.discountAmount,
      discountType: this.state.discountType,
    })

    if (
      subtotal !== nextSubtotal ||
      serviceFee !== nextServiceFee ||
      discounts !== nextDiscounts
    ) {
      return true
    }

    return false
  }

  renderDiscountReasons = (discountKind) => {
    let reasons = DiscountReasons

    if (discountKind === 'partnership') {
      reasons = PartnershipReasons
    } else if (discountKind === 'budget') {
      reasons = BudgetReasons
    }

    return reasons.map((reason) => {
      const { label, value } = reason

      return (
        <option
          key={value}
          value={value}
          selected={value === this.state.reason}
        >
          {label}
        </option>
      )
    })
  }

  renderTipButton(tipPercent) {
    const onClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const tip = this.calculateTip(tipPercent)

      this.setState({ tip })
      this.props.onChange({ tip })
    }

    return (
      <FlexContainer alignItems="flex-start" width="50px">
        <LinkText key={tipPercent} onClick={onClick} label={`${tipPercent}%`} />
        <XSpacing width="12px" />
      </FlexContainer>
    )
  }

  calculateCarbonContrib = (carbonContribPercent) => {
    return parseFloat(
      (
        HUNGRY_CARBON_NEUTRAL_CONTRIBUTION *
        (carbonContribPercent / 100)
      ).toPrecision(2),
    )
  }

  renderCarbonContribButton(carbonContribPercent) {
    const onClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const carbonNeutralContribution =
        this.calculateCarbonContrib(carbonContribPercent)
      this.setState({ carbonNeutralContribution })
      this.props.onChange({ carbonNeutralContribution })
    }

    return (
      <FlexContainer alignItems="flex-start" width="50px">
        <XSpacing width="15px" />
        <LinkText
          key={carbonContribPercent}
          onClick={onClick}
          label={`${carbonContribPercent}%`}
        />
      </FlexContainer>
    )
  }

  renderMarginChart = (margin) => {
    return (
      <div>
        <Margin position={margin > 90 ? 90 : margin}>
          <Pointer>
            <p>{margin}%</p>
            <p>▼</p>
          </Pointer>
        </Margin>
        <GradientBar></GradientBar>
        <BarLabel>
          <div className="label-low">Bad</div>
          <div className="label-avg">Good</div>
          <div className="label-high">Great</div>
        </BarLabel>
      </div>
    )
  }

  renderDeliveryFeeDetails = (
    isHoliday,
    isWeekend,
    deliveryFeePercent,
    deliveryFeeLimit,
  ) => {
    if (!deliveryFeePercent) {
      return <div></div>
    }

    let day = 'Weekday'
    if (isHoliday) {
      day = 'Holiday'
    } else if (isWeekend) {
      day = 'Weekend'
    }

    // workaround for v1 service fee structure being a flat fee
    if (deliveryFeePercent === 10000) {
      return (
        <div>
          {day} flat fee ${deliveryFeeLimit}
        </div>
      )
    }

    let cap = ''
    if (deliveryFeeLimit) {
      cap = `. Cap: $${deliveryFeeLimit}`
    }

    return (
      <div>
        {day} rate {deliveryFeePercent}% of subtotal{cap}
      </div>
    )
  }

  render() {
    const {
      discounts,
      errors,
      zipcode,
      isTaxExempt,
      orderServiceCost: { rate: captRate, routes },
      needsCleanup,
      needsStaffing,
      taxExemptCause,
      tipLocked,
      deliveryFeePercent,
      deliveryFeeLimit,
    } = this.props
    const {
      clientSetUpTime,
      deliveryFee,
      cleanupFee,
      staffingFee,
      numberOfStaff,
      staffingHours,
      staffingRate,
      discountAmount,
      discountReason,
      discountKind,
      discountType,
      isDeliveryFeeOverride,
      isGratuityOverride,
      isStaffingFeeOverride,
      isPredicting,
      predictedServiceCosts,
      showServiceCostBreakdown,
      suppliesCost,
      tip,
      carbonNeutral,
      carbonNeutralContribution,
    } = this.state

    const chefAmount = this.calculateChefAmount().toFixed(2)
    const serviceFee = this.calculateServiceFee()
    const causeString = isTaxExempt
      ? `Tax Exempt (cause: ${taxExemptCause})`
      : ''
    const grossMargin = this.calculateGrossMargin()
    const marginDiff = (grossMargin - 35).toFixed(2)
    const marginMsg = `${marginDiff}% ${marginDiff > 0 ? 'above' : 'below'} 35%`
    const serviceCosts =
      predictedServiceCosts == null ? 'loading...' : '$' + predictedServiceCosts
    const isHoliday =
      clientSetUpTime && HolidayDates[clientSetUpTime.format('M/D')]
    const isWeekend =
      clientSetUpTime && ['6', '7'].includes(clientSetUpTime.format('E'))
    const firstStop =
      routes && routes[0] && routes[0].stops && routes[0].stops[0]

    return (
      <FlexContainer flexDirection="column">
        <FlexContainer flexDirection="column">
          <FlexContainer width="100%" justifyContent="space-between">
            <Label> Subtotal: </Label>
            <div>${this.calculateSubtotal().toFixed(2)} </div>
          </FlexContainer>
          <YSpacing height="15px" />
          <FlexContainer width="100%" justifyContent="space-between">
            <Label>Pre-Tax Price Per Head:</Label>
            <div>${this.calculateSubtotalPreTaxPricePerHead().toFixed(2)} </div>
          </FlexContainer>
          <YSpacing height="15px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexContainer
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Label>Discount:</Label>
              <XSpacing width="15px" />
              <AuthorizedInteractable
                roles={['master admin', 'sales lead', 'sales rep']}
              >
                <XSpacing width="15px" />
                <Dropdown
                  width="90px"
                  marginBottom="0"
                  value={discountKind}
                  onChange={(e) =>
                    this.onChangeDiscount({ kind: e.target.value })
                  }
                >
                  <option value="">Kind</option>
                  {DiscountKinds.map((r) => (
                    <option
                      key={r.value}
                      value={r.value}
                      selected={r.value === discountKind}
                    >
                      {r.label}
                    </option>
                  ))}
                </Dropdown>
              </AuthorizedInteractable>
              {discountKind && (
                <AuthorizedInteractable
                  roles={['master admin', 'sales lead', 'sales rep']}
                >
                  <XSpacing width="15px" />
                  <Dropdown
                    width="90px"
                    marginBottom="0"
                    value={discountReason}
                    onChange={(e) =>
                      this.onChangeDiscount({ reason: e.target.value })
                    }
                  >
                    <option value="">*Reason</option>
                    {this.renderDiscountReasons(discountKind)}
                  </Dropdown>
                </AuthorizedInteractable>
              )}
              <XSpacing width="15px" />
              <AuthorizedInteractable
                roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
              >
                <Dropdown
                  width="90px"
                  marginBottom="0"
                  value={discountType}
                  onChange={(e) =>
                    this.onChangeDiscount({ type: e.target.value })
                  }
                >
                  <option value=""> Type </option>
                  {DiscountTypes.map((r) => (
                    <option
                      key={r.value}
                      value={r.value}
                      selected={r.value === discountType}
                    >
                      {r.label}
                    </option>
                  ))}
                </Dropdown>
              </AuthorizedInteractable>
            </FlexContainer>
            {discountType ? (
              <AuthorizedInteractable
                roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
              >
                <CurrencyInput
                  width="80px"
                  value={discountAmount || ''}
                  decimals={discountType == 'flat' ? 2 : 0}
                  prefix={discountType == 'flat' ? '$' : ''}
                  suffix={discountType == 'flat' ? '' : '%'}
                  onChange={(amount) => this.onChangeDiscount({ amount })}
                />
              </AuthorizedInteractable>
            ) : (
              '--'
            )}
          </FlexContainer>
          {discounts && discounts.length > 0 && (
            <div className="flex justify-between">
              <div className="text-lg regular">Marketplace Discounts:</div>
              {errors.orderDiscounts && (
                <div className="error-message">{errors.orderDiscounts}</div>
              )}
            </div>
          )}
          {discounts &&
            discounts
              .filter((d) => !d._destroy)
              .map((discount) => (
                <div className="flex justify-between mb-2" key={discount.id}>
                  <p className="text-lg regular">{`PromoCode: ${
                    discount.code || discount.kind
                  }`}</p>
                  {discount && (
                    <img
                      className="w-5 ml-3 box-content"
                      onClick={this.onRemoveMPDiscount(discount)}
                      src={trash}
                    />
                  )}
                </div>
              ))}
          <YSpacing height="15px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexContainer width="100%" justifyContent="flex-start">
              <FlexContainer flexDirection="column">
                <Label>Delivery Fee:</Label>
                {this.renderDeliveryFeeDetails(
                  isHoliday,
                  isWeekend,
                  deliveryFeePercent,
                  deliveryFeeLimit,
                )}
              </FlexContainer>
              <XSpacing width="5px" />
              <AuthorizedInteractable
                roles={['master admin', 'sales lead']}
                customStyle={{ display: 'inline-flex' }}
              >
                <div>
                  <input
                    className="m-2"
                    type="checkbox"
                    onChange={this.onChangeIsDeliveryFeeOverride}
                    checked={isDeliveryFeeOverride}
                  />
                  <label> Delivery Fee Override </label>
                </div>
              </AuthorizedInteractable>
            </FlexContainer>
            <CurrencyInput
              width="80px"
              value={deliveryFee}
              onChange={this.onChangeServiceFee('deliveryFee')}
              disabled={!isDeliveryFeeOverride}
              testId="deliveryFee"
            />
          </FlexContainer>

          <YSpacing height="5px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexContainer width="100%" justifyContent="flex-start">
              <Label> Cleanup Fee:</Label>
              <XSpacing width="10px" />
            </FlexContainer>
            <CurrencyInput
              delay={350}
              width="80px"
              value={cleanupFee}
              onChange={this.onChangeCleanupFee}
              disabled={!needsCleanup}
              testId="cleanupFee"
            />
          </FlexContainer>

          <YSpacing height="5px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexContainer
              width="100%"
              justifyContent="flex-start"
              flexDirection="column"
            >
              <Label> Staffing Fee:</Label>
              <div>
                <input
                  className="m-2"
                  type="checkbox"
                  onChange={this.onChangeIsStaffingFeeOverride}
                  checked={isStaffingFeeOverride}
                  disabled={!needsStaffing}
                />
                <label className="ml-3"> Staffing Override </label>
              </div>
            </FlexContainer>
            <CurrencyInput
              label="# staff"
              prefix=""
              decimals={0}
              width="60px"
              margin="4px"
              value={numberOfStaff}
              onChange={this.onChangeServiceFee('numberOfStaff')}
              disabled={!needsStaffing || !isStaffingFeeOverride}
            />
            <CurrencyInput
              label="# hours"
              prefix=""
              decimals={0}
              width="60px"
              margin="4px"
              value={staffingHours}
              onChange={this.onChangeServiceFee('staffingHours')}
              disabled={!needsStaffing || !isStaffingFeeOverride}
            />
            <CurrencyInput
              label="rate"
              prefix=""
              decimals={0}
              width="60px"
              margin="4px"
              value={staffingRate}
              onChange={this.onChangeServiceFee('staffingRate')}
              disabled={!needsStaffing || !isStaffingFeeOverride}
            />
            <XSpacing width="10px" />
            <CurrencyInput
              label="$ Total"
              prefix="$"
              decimals={2}
              width="80px"
              margin="4px"
              value={staffingFee}
              disabled={true}
            />
          </FlexContainer>

          <YSpacing height="5px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexContainer width="100%" justifyContent="flex-start">
              <Label> Total Service Fee:</Label>
              <XSpacing width="10px" />
            </FlexContainer>
            <div>${(serviceFee || 0).toFixed(2)}</div>
          </FlexContainer>

          <YSpacing height="15px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <Label>Sales Tax:</Label>{' '}
              <span className="blue-text"> {causeString}</span>
              <LinkText
                fontSize="11px"
                onClick={() => this.calculateTaxRates(true)}
                label="Recalculate Tax"
              />
            </div>
            <p>
              {zipcode ? (
                `$${this.calculateTax().toFixed(2)}`
              ) : (
                <span className="red-text">needs address</span>
              )}
            </p>
          </FlexContainer>
          <YSpacing height="15px" />
          {this.hasNoTaxRates() ? (
            <div> No taxes for this location </div>
          ) : (
            <div className="flex flex-col">
              <div>Tax Rates</div>
              <div className="flex justify-between">
                <div className="mr-4">{`Food: ${this.getTotalFoodTaxRate()}%`}</div>
                <div className="mr-4">{`Delivery: ${this.getTotalDeliveryTaxRate()}%`}</div>
                <div className="mr-4">{`Services: ${this.getTotalServicesTaxRate()}%`}</div>
                <div className="mr-4">{`Alcohol: ${this.getTotalAlcoholTaxRate()}%`}</div>
              </div>
            </div>
          )}
          <YSpacing height="15px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexContainer flexDirection="column">
              <Label>Gratuity:</Label>
              <div>
                <input
                  className="m-2"
                  type="checkbox"
                  onChange={this.onChangeIsGratuityOverride}
                  checked={isGratuityOverride}
                />
                <label className="ml-3"> Gratuity Override </label>
              </div>
              {isGratuityOverride && !tipLocked && (
                <div className="flex flex-row">
                  {[5, 10, 15, 20, 25].map((tipPercent) =>
                    this.renderTipButton(tipPercent),
                  )}
                </div>
              )}
              {this.renderTipInfo()}
            </FlexContainer>
            <div className="items-center flex flex-row">
              <CurrencyInput
                width="80px"
                value={tip}
                onChange={this.onChangeCurrency('tip')}
                disabled={tipLocked || !isGratuityOverride}
              />
            </div>
          </FlexContainer>
          {tipLocked && (
            <span className="red-text">
              {' '}
              Gratuity locked because of multiple tip adjustments. Please edit
              on Ops Dashboard.{' '}
            </span>
          )}
          <YSpacing height="15px" />
          {carbonNeutral && (
            <Fragment>
              <FlexContainer
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <FlexContainer>
                  <Label>Carbon Neutral Contribution:</Label>
                  {[50, 100].map((carbonContrib) =>
                    this.renderCarbonContribButton(carbonContrib),
                  )}
                </FlexContainer>
                <CurrencyInput
                  width="80px"
                  value={carbonNeutralContribution}
                  onChange={(v) => this.onChangeCarbonNeutralContrib(v)}
                  disabled={!carbonNeutral}
                />
              </FlexContainer>
              <YSpacing height="15px" />
            </Fragment>
          )}
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label>Total:</Label>
            <div>${this.calculateTotal().toFixed(2)}</div>
          </FlexContainer>
          <YSpacing height="15px" />
          <FlexContainer
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label>Post-Tax Price Per Head:</Label>
            <div>${this.calculateTotalPostTaxPricePerHead().toFixed(2)}</div>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <MarginBox>
            <h2>Margin Details</h2>
            <YSpacing height="5px" />
            <LinkText
              fontSize="13px"
              onClick={
                isPredicting
                  ? () => undefined
                  : () => this.onPredictServiceCosts(true)
              }
              label={
                isPredicting
                  ? 'Updating Predictions...'
                  : 'Update Cost Predictions'
              }
            />
            <YSpacing height="10px" />
            <FlexContainer width="100%" justifyContent="space-between">
              <div> Chef Cost: </div>
              <div>${chefAmount} </div>
            </FlexContainer>
            <YSpacing height="5px" />
            <div
              className="relative"
              width="100%"
              onMouseEnter={() =>
                this.setState({ showServiceCostBreakdown: true })
              }
              onMouseLeave={() =>
                this.setState({ showServiceCostBreakdown: false })
              }
            >
              <FlexContainer width="100%" justifyContent="space-between">
                {showServiceCostBreakdown && (
                  <div className="z-10 bg-blue-600 l-150 p-3 py-5 absolute w-64 rounded">
                    <p className="text-center text-white text-xl bold truncate md:overflow-clip">
                      {' '}
                      Service Costs Breakdown{' '}
                    </p>
                    <div className="mt-3">
                      <p className="text-base text-white">
                        <span className="bold text-white mb-1">Rate: </span>
                        {captRate ? `$${captRate.toFixed(2)}` : 'loading...'}
                      </p>
                      <p className="text-base text-white">
                        <span className="bold text-white mb-1">Captains: </span>
                        {routes ? routes.length : 'loading...'}
                      </p>
                      <p className="text-base text-white">
                        <span className="bold text-white mb-1">
                          Avg Hours per Capt:{' '}
                        </span>
                        {routes
                          ? (
                              routes.reduce(
                                (sum, rt) => sum + rt.duration,
                                0.0,
                              ) / (routes.length || 1)
                            ).toFixed(2)
                          : 'loading...'}
                      </p>
                      <p className="text-base text-white">
                        <span className="bold text-white mb-1">
                          Large Headcount:{' '}
                        </span>
                        {routes
                          ? firstStop && firstStop.isLgHeadcount
                            ? 'Yes'
                            : 'No'
                          : 'loading...'}
                      </p>
                      {(routes || []).map((route, i) => {
                        return (
                          <p className="text-base text-white mb-4" key={i}>
                            <span className="bold text-white mb-1">
                              Route {i + 1}:{' '}
                            </span>
                            {route.duration.toFixed(2)} hours
                            {route.stops.map((stop, j) => (
                              <p
                                className="ml-4 mb-2 text-sm text-white"
                                key={j}
                              >
                                <span className="underline">{stop.type}</span>
                                <br />
                                Stop Mins: {stop.stopBufferTime || 0}
                                <br />
                                Drive From Mins:{' '}
                                {stop.travelFromStopDriveTime || 0}
                                <br />
                                Drive From Buffer Mins:{' '}
                                {stop.travelFromStopBufferTime || 0}
                                <br />
                                Rush Hour: {stop.isRushHour ? 'Yes' : 'No'}
                                <br />
                                High Traffic:{' '}
                                {stop.isHighTraffic ? 'Yes' : 'No'}
                                <br />
                              </p>
                            ))}
                          </p>
                        )
                      })}
                    </div>
                  </div>
                )}
                <LinkText fontSize="12px" label="Predicted Service Cost:" />
                <div>{serviceCosts}</div>
              </FlexContainer>
            </div>
            <YSpacing height="5px" />
            <FlexContainer width="100%" justifyContent="space-between">
              <div> Supplies Cost: </div>
              <div>${suppliesCost} </div>
            </FlexContainer>
            <DividerLine margin="10px 0" />
            <p className="margin-msg">HUNGRY Margin Goal: Above 35%</p>
            <p className="margin-msg">
              Your Margin: {grossMargin}% ({marginMsg})
            </p>
            <YSpacing height="20px" />
            {this.renderMarginChart(grossMargin)}
          </MarginBox>
        </FlexContainer>
      </FlexContainer>
    )
  }
}

const Pointer = styled.div`
  width: 50px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  p {
    display: inline-block;
  }
`

const MarginBox = styled.div`
  background: #f0f6ff;
  width: 100%;
  padding: 25px;
  border: 1px solid #a3b5d7;
  h2 {
    font-family: 'bold';
    color: ${colors.gray400};
    font-size: 16px;
  }
  .margin-msg {
    font-family: 'bold';
    font-size: 13px;
    color: ${colors.gray400};
  }
`

const Margin = styled.div`
  transition: 0.2s ease-in-out all;
  margin-left: ${(props) => props.position}%;
`
const GradientBar = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 10px;
  background: rgb(238, 52, 109);
  background: linear-gradient(
    29deg,
    rgba(238, 52, 109, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
`

const BarLabel = styled.div`
  display: flex;
  .label-low,
  .label-avg,
  .label-high {
    margin-top: 3px;
    color: ${colors.gray300};
    font-size: 12px;
    font-family: 'bold';
  }
  .label-avg {
    margin-left: 35%;
  }
  .label-high {
    margin-left: auto;
  }
`

TotalSection.propTypes = {
  orderSettings: PropTypes.object,
  dropoffAddress: PropTypes.object,
  adjustedChefPayouts: PropTypes.arrayOf(PropTypes.object),
  captRate: PropTypes.number,
  clientSetUpTime: PropTypes.instanceOf(Moment),
  createdAt: PropTypes.instanceOf(Moment),
  chefs: PropTypes.arrayOf(PropTypes.object),
  discount: PropTypes.object,
  discounts: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  headCount: PropTypes.string,
  initialBalances: PropTypes.object,
  isDeliveryFeeOverride: PropTypes.bool,
  isGratuityOverride: PropTypes.bool,
  isStaffingFeeOverride: PropTypes.bool,
  isFreeDelivery: PropTypes.bool,
  isPaid: PropTypes.bool,
  isTaxExempt: PropTypes.bool,
  isVCX: PropTypes.bool,
  needsCleanup: PropTypes.bool,
  needsStaffing: PropTypes.bool,
  numChefs: PropTypes.number,
  orderId: PropTypes.string,
  orderServiceCost: PropTypes.object,
  orderType: PropTypes.string,
  predictedServiceCosts: PropTypes.number,
  subtotal: PropTypes.number,
  deliveryFee: PropTypes.number,
  cleanupFee: PropTypes.number,
  staffingFee: PropTypes.number,
  numberOfStaff: PropTypes.number,
  staffingHours: PropTypes.number,
  staffingRate: PropTypes.number,
  serviceFee: PropTypes.number,
  serviceType: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  supplyBreakdown: PropTypes.object,
  tax: PropTypes.number,
  taxRecord: PropTypes.object,
  taxRates: PropTypes.arrayOf(PropTypes.number),
  deliveryTaxRates: PropTypes.arrayOf(PropTypes.number),
  servicesTaxRates: PropTypes.arrayOf(PropTypes.number),
  alcoholTaxRates: PropTypes.arrayOf(PropTypes.number),
  servicesBreakdown: PropTypes.object,
  taxExemptCause: PropTypes.string,
  tip: PropTypes.number,
  tipLocked: PropTypes.bool,
  total: PropTypes.number,
  userPermissions: PropTypes.array,
  zipcode: PropTypes.string,
  deliveryFeePercent: PropTypes.number,
  deliveryFeeLimit: PropTypes.number,
  defaultTipPercent: PropTypes.number,
  serviceInputsChanged: PropTypes.bool,
  routingInputsChanged: PropTypes.bool,

  calculateChefAmount: PropTypes.func,
  calculateDiscounts: PropTypes.func,
  calculateDeliveryFee: PropTypes.func,
  calculateGrossMargin: PropTypes.func,
  calculatePreTaxTotal: PropTypes.func,
  calculateServiceFee: PropTypes.func,
  calculateStaffingFee: PropTypes.func,
  calculateSubtotal: PropTypes.func,
  calculateTax: PropTypes.func,
  calculateTip: PropTypes.func,
  calculateTotal: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  getTaxRates: PropTypes.func,
  onChange: PropTypes.func,
  updateServiceAndSupplyCosts: PropTypes.func,
}

TotalSection.defaultProps = {
  discountAmount: 0,
  discountType: '',
  discountReason: '',
  discountKind: '',
  isFreeDelivery: false,
  isTaxExempt: false,
  isPaid: false,
  needsCleanup: false,
  numberOfStaff: 0,
  staffingHours: 0,
  orderId: undefined,
  orderServiceCost: {},
  subtotal: 0,
  serviceFee: 0,
  supplyBreakdown: undefined,
  suppliesCost: 0,
  tip: 0,
}

export default TotalSection
