import {
  ClientReportingProgram,
  LoadClientReportingProgramsParams,
} from '@types'

interface LoadClientReportingProgramsCoordinator {
  RestService: {
    get: (
      path: string,
      params: LoadClientReportingProgramsParams,
    ) => Promise<ClientReportingProgram[]>
  }
  AccountService: {
    setClientReportingPrograms: (programs: ClientReportingProgram[]) => void
  }
  HandleError: any
}
export const LoadClientReportingPrograms =
  ({
    RestService,
    AccountService,
    HandleError,
  }: LoadClientReportingProgramsCoordinator) =>
  async (params: LoadClientReportingProgramsParams) => {
    const req = {} as LoadClientReportingProgramsParams
    if (params.name) {
      req.name = params.name
    }
    if (params.clientId) {
      req.clientId = params.clientId
    }
    if (params.clientIds) {
      if (Array.isArray(params.clientIds)) {
        req.clientIds = params.clientIds.join(',')
      } else {
        req.clientIds = params.clientIds
      }
    }
    if (params.dinerProfileIds) {
      if (Array.isArray(params.dinerProfileIds)) {
        req.dinerProfileIds = params.dinerProfileIds.join(',')
      } else {
        req.dinerProfileIds = params.dinerProfileIds
      }
    }
    if (params.isDefault) {
      req.isDefault = params.isDefault
    }
    if (params.disabled) {
      req.disabled = params.disabled
    }

    try {
      const reportingPrograms = await RestService.get(
        '/client-reporting-programs',
        req,
      )
      AccountService.setClientReportingPrograms(reportingPrograms)

      return reportingPrograms
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

interface LoadClientReportingProgramCoordinator {
  RestService: {
    get: (path: string) => Promise<ClientReportingProgram>
  }
  HandleError: any
}
type GetClientReportingProgramBy = 'client' | 'dinerProfile' | 'id'
export const LoadClientReportingProgram =
  ({ RestService, HandleError }: LoadClientReportingProgramCoordinator) =>
  async (
    id: string,
    getBy: GetClientReportingProgramBy | undefined = undefined,
  ) => {
    let uri: string
    if (!getBy || getBy === 'id') {
      uri = `/client-reporting-programs/${id}`
    } else if (getBy === 'client') {
      uri = `/client-reporting-programs/client/${id}`
    } else {
      uri = `/client-reporting-programs/diner-profile/${id}`
    }

    try {
      const reportingProgram = await RestService.get(uri)

      return reportingProgram
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

interface SaveClientReportingProgramCoordinator {
  RestService: {
    post: (
      path: string,
      data: ClientReportingProgram,
    ) => Promise<ClientReportingProgram>
    put: (
      path: string,
      data: ClientReportingProgram,
    ) => Promise<ClientReportingProgram>
  }
  AccountService: {
    clearEditClientReportingProgram: () => void
  }
  UIService: {
    EditClientReportingProgram: {
      close: () => void
    }
  }
  HandleError: any
}
export const SaveClientReportingProgram =
  ({
    RestService,
    AccountService,
    UIService,
    HandleError,
  }: SaveClientReportingProgramCoordinator) =>
  async (data: ClientReportingProgram) => {
    try {
      let reportingProgram
      if (data.id) {
        reportingProgram = await RestService.put(
          `/client-reporting-programs`,
          data,
        )
      } else {
        reportingProgram = await RestService.post(
          `/client-reporting-programs`,
          data,
        )
      }
      AccountService.clearEditClientReportingProgram()
      UIService.EditClientReportingProgram.close()

      return reportingProgram
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

interface EditClientReportingProgramCoodinator {
  AccountService: {
    setEditClientReportingProgram: (
      reportingProgram: ClientReportingProgram,
    ) => void
    clearEditClientReportingProgram: () => void
  }
  UIService: {
    EditClientReportingProgram: {
      show: () => void
      close: () => void
    }
  }
}
export const EditClientReportingProgram =
  ({ AccountService, UIService }: EditClientReportingProgramCoodinator) =>
  (reportingProgram: ClientReportingProgram) => {
    AccountService.setEditClientReportingProgram(reportingProgram)
    UIService.EditClientReportingProgram.show()
  }
export const CloseEditClientReportingProgramModal =
  ({ AccountService, UIService }: EditClientReportingProgramCoodinator) =>
  () => {
    AccountService.clearEditClientReportingProgram()
    UIService.EditClientReportingProgram.close()
  }
