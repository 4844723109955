import { isEmpty } from 'lodash'
import {
  DeleteOrderable,
  DelayedUpdateOrderable,
  EditOrderable,
} from '@coordinators/orderable'

export const AdjustChefPayouts =
  ({
    OrderService,
    RestService,
    UIService,
    pRequestUpdateChefPayouts,
    pResponseOrder,
    HandleError,
  }) =>
  async ({ orderId, orderStatus, chefPayouts }) => {
    const data = pRequestUpdateChefPayouts(chefPayouts)
    if (orderStatus) {
      data.order_statuses_attributes = [{ code: orderStatus }]
    }
    try {
      let order = await RestService.put(
        `/api/admin/orders/${orderId}`,
        {
          order: data,
        },
        { timeout: 30000 },
      )
      order = pResponseOrder(order)
      OrderService.setOrderable(order)
      UIService.FlashMessage.displaySuccessMessage(
        'Successfully updated chef payouts!',
      )
    } catch (error) {
      HandleError({ error, namespace: 'editChefPayoutsModal' })

      return
    }
  }

export const ChangeOrderStatus =
  ({ OrderService, RestService, pResponseOrder, UIService, HandleError }) =>
  async (code) => {
    try {
      const orderId = OrderService.orderableId()
      let order = await RestService.put(
        `/api/admin/orders/${orderId}`,
        {
          order: { order_statuses_attributes: [{ code }] },
        },
        { timeout: 60000 },
      )
      order = pResponseOrder(order)
      OrderService.setOrderable(order)
      UIService.FlashMessage.displaySuccessMessage(
        `Order status changed to ${code}`,
      )
    } catch (error) {
      HandleError({ error, namespace: 'orderOverview' })

      return
    }
  }

export const UpdateOrdersDinerProfileId =
  ({ RestService, pResponseOrder, HandleError, pResponseError }) =>
  async (hqLocaleMap, params, callbackFn) => {
    const batchSize = 1
    for (let i = 0; i < params.length; i += batchSize) {
      const batch = params.slice(i, i + batchSize)

      try {
        const promises = batch.map(({ orderId, dinerProfileId }, idx) =>
          RestService.put(
            `/api/admin/orders/${orderId}`,
            {
              order: { diner_profile_id: dinerProfileId },
            },
            {
              timeout: 30000,
            },
          )
            .then((order) => {
              const formattedOrder = pResponseOrder(order)
              if (formattedOrder.proposalId) {
                return RestService.put(
                  `/api/admin/proposals/${formattedOrder.proposalId}`,
                  {
                    proposal: { diner_profile_id: dinerProfileId },
                    skip_validation: true,
                  },
                  {
                    timeout: 30000,
                  },
                )
                  .then(() => callbackFn(formattedOrder, i + idx, undefined))
                  .catch((error) => {
                    const { errors, errorMessage } = pResponseError(error)
                    const message = errors.message
                      ? errors.message
                      : errorMessage
                    callbackFn(
                      null,
                      i + idx,
                      `Order updated but proposal failed: ${message}`,
                    )
                  })
              } else {
                callbackFn(formattedOrder, i + idx, undefined)
              }
            })
            .catch((error) => {
              const { errors, errorMessage } = pResponseError(error)
              const message = errors.message ? errors.message : errorMessage
              callbackFn(null, i + idx, message)
            }),
        )
        await Promise.all(promises)
      } catch (error) {
        HandleError({ error, doFlash: true })
      }
    }
  }

export const DelayedUpdateOrder =
  ({ OrderService, UIService }) =>
  (data) => {
    return DelayedUpdateOrderable({
      reduxService: OrderService,
      UIService,
      UIServiceEditOrderable: UIService.EditOrder,
    })(data)
  }

export const DeleteOrder =
  ({ OrderService, RestService, UIService, HandleError }) =>
  async () => {
    return DeleteOrderable({
      reduxService: OrderService,
      RestService,
      UIService,
      HandleError,
      url: '/api/admin/orders/',
    })()
  }

export const EditOrder =
  ({ OrderService, RestService, UIService, pResponseEditOrder }) =>
  async (section) => {
    return await EditOrderable({
      reduxService: OrderService,
      OrderService,
      RestService,
      UIServiceEditOrderable: UIService.EditOrder,
      pResponseEdit: pResponseEditOrder,
      url: '/api/admin/orders/',
    })(section)
  }

export const CopyOrder =
  ({ OrderService, RestService, UIService, pCopyOrder, pResponseEditOrder }) =>
  async () => {
    const orderId = OrderService.getState().order.id
    const orderableJson = await RestService.get(`/api/admin/orders/${orderId}`)
    const order = pResponseEditOrder({
      orderableJson,
      calculateDiscounts: OrderService.calculateDiscounts,
    })
    const copiedOrder = pCopyOrder(order)
    OrderService.updateNewOrderable(copiedOrder)
    UIService.EditOrder.show('new')
  }

export const SaveOrder =
  ({
    OrderService,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pRequestUpdateOrder,
    pResponseGeneric,
    pResponseOrder,
    pResponseOrderError,
    HandleError,
    CheckBatchInvoicedBalances,
  }) =>
  async (data, callbackFn = {}) => {
    const { addToRecurringBatch, isCopy, chefs } = data
    const {
      chefAmount,
      cleanupFee,
      preTaxTotal,
      serviceFee,
      subtotal,
      tax,
      total,
    } = OrderService.calculateAll(data)
    data = {
      ...data,
      chefAmount,
      cleanupFee,
      preTaxTotal,
      serviceFee,
      subtotal,
      tax,
      total,
    }
    const hasGoodZip =
      data.orderType === 'VCX'
        ? data.billingAddress && data.billingAddress.zip
        : data.dropoffAddress && data.dropoffAddress.zip
    if (!hasGoodZip) {
      UIService.FlashMessage.displayWarningMessage(
        'Unable to save order without valid zipcode.',
      )
    }
    const noQuantity = []
    chefs.forEach((chef) => {
      const { orderMenuItems } = chef
      orderMenuItems.forEach((item) => {
        if (item.quantity === 0) {
          noQuantity.push(item)
        }
        item.childItems.forEach((child) => {
          if (child.quantity === 0) {
            noQuantity.push(child)
          }
        })
      })
    })
    if (noQuantity.length > 0) {
      UIService.FlashMessage.displayFailureMessage(
        'The following items have a quantity of zero: ' +
          noQuantity.map((item) => item.name).join(', '),
      )

      return
    }
    const orderData = pRequestUpdateOrder(data)
    const createOrUpdate = orderData.id ? 'Updated' : 'Created'
    let order
    let postSaveTotalCheck
    try {
      if (orderData.id) {
        // update
        order = await RestService.put(
          `/api/admin/orders/${orderData.id}`,
          {
            order: orderData,
            add_to_recurring_batch: addToRecurringBatch,
          },
          { timeout: 90000 },
        )
        await CheckBatchInvoicedBalances({
          RestService,
          UIService,
          HandleError,
        })(orderData.id)
      } else {
        // create
        order = await RestService.post(
          '/api/admin/orders',
          {
            order: orderData,
            add_to_recurring_batch: addToRecurringBatch,
          },
          { timeout: 90000 },
        )
      }
    } catch (error) {
      HandleError({
        RouterService,
        SessionService,
        UIService,
        pResponseError: pResponseOrderError,
      })({ error, namespace: 'editOrderModal' })

      return
    }

    order = pResponseOrder(order)
    if (!isCopy) {
      OrderService.setOrderable(order)
    }

    // Close modal before clearing state
    UIService.EditOrder.close()

    const isEditMode = UIService.EditOrder.getMode() === 'edit'
    if (isEditMode) {
      OrderService.clearEditOrderable()
    } else {
      OrderService.clearNewOrderable()
    }

    const serviceOrder = pResponseGeneric(
      await RestService.get(
        `/api/admin/services_dashboard/${order.orderNumber}`,
      ),
    )
    if (serviceOrder && serviceOrder.routes && serviceOrder.adminUpdates) {
      const {
        captainCount,
        chefIds,
        chefItems,
        dropoffAddress,
        headCount,
        setupByDate,
        setupByTime,
      } = serviceOrder.adminUpdates
      if (
        captainCount ||
        chefIds ||
        dropoffAddress ||
        setupByDate ||
        setupByTime
      ) {
        UIService.FlashMessage.displayWarningMessage(
          'Route recalculation necessary! Please contact services.',
        )
      } else if (chefItems || headCount) {
        UIService.FlashMessage.displayWarningMessage(
          'Packaging recalculation necessary! Please contact services.',
        )
      }
    } else if (
      !serviceOrder &&
      order.orderType !== 'VCX' &&
      order.statuses[0].code !== 'canceled'
    ) {
      UIService.FlashMessage.displayFailureMessage(
        'Routes were not able to be calculated! Please contact the Tech Team.',
      )
    }

    order.orderMenuItems.forEach((i) => {
      if (
        i.menuItem &&
        (i.price !== i.menuItem.price || i.cost !== i.menuItem.cost)
      ) {
        UIService.FlashMessage.displayWarningMessage(
          'Please reach out to the sales rep on the order to confirm the client price',
        )

        return
      }
    })
    if (orderData.id && postSaveTotalCheck) {
      await postSaveTotalCheck()
    }

    if (callbackFn) {
      UIService.FlashMessage.displaySuccessMssgOptions(
        `Order ${createOrUpdate}: `,
        {
          buttonTitle: `${order.orderNumber}`,
          buttonAction: () => callbackFn(order),
          isLink: true,
        },
      )
    }

    UIService.EditOrder.close()
    // refresh orders
  }

export const UpdateOrderPaymentMethod =
  ({
    OrderService,
    RestService,
    pRequestUpdateOrderPaymentMethod,
    pResponseOrder,
    HandleError,
  }) =>
  async (data) => {
    const orderData = pRequestUpdateOrderPaymentMethod(data)
    let order

    try {
      if (!orderData.id) {
        return
      }
      order = await RestService.put(
        `/api/admin/orders/${orderData.id}`,
        {
          order: orderData,
        },
        { timeout: 30000 },
      )
    } catch (error) {
      HandleError({ error, namespace: 'manualCharge' })

      return
    }

    order = pResponseOrder(order)
    OrderService.setOrderable(order)
  }

export const EditChefNotes =
  ({ RestService }) =>
  async (chefNotesData) => {
    if (chefNotesData.length > 0) {
      for (const data of chefNotesData) {
        const { id } = data.chef_order_instruction
        if (id) {
          await RestService.put(
            `/api/admin/chef_order_instructions/${id}`,
            data,
            { timeout: 30000 },
          )
        } else {
          await RestService.post('/api/admin/chef_order_instructions', data, {
            timeout: 30000,
          })
        }
      }
    }
  }

export const UpdateServicesInfo =
  ({
    OrderService,
    RestService,
    pRequestUpdateOrderServiceInfo,
    pResponseOrder,
  }) =>
  async (data) => {
    const orderId = OrderService.getState().order.id
    data = pRequestUpdateOrderServiceInfo(data)
    let order = await RestService.put(`/api/admin/orders/${orderId}`, data)
    order = pResponseOrder(order)
    OrderService.setOrderable(order)
  }

export const ConfirmPaymentMethod =
  ({ UIService }) =>
  async (orderable) => {
    const {
      account: { payByMethod } = {},
      orderableType,
      unpaidAmount,
      paymentMethod,
      total,
    } = orderable

    if (
      orderableType !== 'Order' ||
      (total > 0 && parseFloat(unpaidAmount) === 0) ||
      (paymentMethod !== null && !isEmpty(paymentMethod)) ||
      payByMethod !== 'Pay By CC'
    ) {
      return true
    }

    const confirm = await UIService.ConfirmationModal.show({
      text: 'Are you sure you want to proceed without including a payment method?',
    })

    return confirm
  }
