import { BaseService } from 'hungry-core2'
import actions from '@actions'

export default class InfoModal extends BaseService {
  displayInfoModal = ({ title, content }) => {
    this.dispatch(actions.displayInfoModal({ title, content }))
  }

  dismissInfoModal = () => {
    this.dispatch(actions.dismissInfoModal())
  }
}
