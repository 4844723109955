export const TOGGLE_EDIT_ACCOUNT_MODAL = 'TOGGLE_EDIT_ACCOUNT_MODAL'
export const TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL =
  'TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL'
export const TOGGLE_EDIT_DINER_PROFILE_MODAL = 'TOGGLE_EDIT_DINER_PROFILE_MODAL'
export const TOGGLE_SEARCH_VIA_HUBSPOT_MODAL = 'TOGGLE_SEARCH_VIA_HUBSPOT_MODAL'
export const TOGGLE_EDIT_CLIENT_REPORTING_PROGRAM_MODAL =
  'TOGGLE_EDIT_CLIENT_REPORTING_PROGRAM_MODAL'

export const toggleEditAccountModal = (show) => ({
  type: TOGGLE_EDIT_ACCOUNT_MODAL,
  show,
})
export const toggleEditAccountContactModal = (show) => ({
  type: TOGGLE_EDIT_ACCOUNT_CONTACT_MODAL,
  show,
})
export const toggleEditDinerProfileModal = (show) => ({
  type: TOGGLE_EDIT_DINER_PROFILE_MODAL,
  show,
})
export const toggleSearchViaHubSpotModal = (show) => ({
  type: TOGGLE_SEARCH_VIA_HUBSPOT_MODAL,
  show,
})
export const toggleEditClientReportingProgramModal = (show) => ({
  type: TOGGLE_EDIT_CLIENT_REPORTING_PROGRAM_MODAL,
  show,
})
