import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import LabelInfo from '@components/common/form/LabelInfo'
import { AutocompleteInput } from '@containers/common/form'
import { TechHelpForm } from '@components/common'
import LinkText from '@components/common/form/LinkText'
import { colors } from '../../../../constants'
import Button from '@components/common/form/Button'
import Moment from 'moment-timezone'
import { FiEdit2 } from 'react-icons/fi'
import { IoCloseOutline } from 'react-icons/io5'
import { TotalSection } from '@containers/order/edit/sections'
import { Input, DateInput, TimeInput } from '@components/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import { OrderablePropType } from './propTypes'

const EditOrderSection3 = (props) => {
  const {
    orderable,
    orderSettings,
    errors,
    headquarterId,
    previousSection,

    loadChefs,
    renderChef,
    onSelectChef,
    navigateToSection,
    onSelectSetupDate,
    onChange,
    onDeleteAllChefs,
    updateServiceAndSupplyCosts,
    saveOrderRoles,
    onHide,
    onSave,
  } = props

  const {
    id,
    account,
    clientSetUpTime,
    orderableType,
    orderType,
    conceptName,
    chefs,
    headCount,
    adjustedChefPayouts,
    dropoffAddress,
    createdAt,
    discount,
    discounts,
    initialBalances,
    isDeliveryFeeOverride,
    isGratuityOverride,
    isStaffingFeeOverride,
    isTaxExempt,
    isPaid,
    serviceType,
    needsCleanup,
    needsStaffing,
    orderServiceCost,
    servicesBreakdown,
    deliveryFee,
    cleanupFee,
    staffingFee,
    numberOfStaff,
    staffingHours,
    staffingRate,
    serviceFee,
    subtotal,
    predictedServiceCosts,
    supplyBreakdown,
    tax,
    taxRecord,
    taxRates,
    deliveryTaxRates,
    servicesTaxRates,
    alcoholTaxRates,
    orderTaxExemptCause,
    tip,
    tipLocked,
    carbonNeutral,
    carbonNeutralContribution,
    total,
    deliveryFeePercent,
    deliveryFeeLimit,
    defaultTipPercent,
    billingAddress,
    serviceInputsChanged,
    routingInputsChanged,
  } = orderable
  const { avgPricePerPerson, avgPricePerPersonType } = account || {}

  const isVCX = orderType === 'VCX'
  const isSnackPack = orderType === 'Snack Pack'
  const taxZip = !isVCX
    ? dropoffAddress && dropoffAddress.zip
    : billingAddress && billingAddress.zip

  const navigateToPreviousSection = () => {
    navigateToSection(previousSection)
  }

  const [isEditDetails, setIsEditDetails] = useState(false)

  const dayString =
    clientSetUpTime && Moment.isMoment(clientSetUpTime)
      ? clientSetUpTime.format('dddd, MMMM Do YYYY')
      : ''
  const dateString =
    clientSetUpTime && Moment.isMoment(clientSetUpTime)
      ? clientSetUpTime.format('h:mm a')
      : ''
  const avgPppString =
    avgPricePerPerson && parseFloat(avgPricePerPerson)
      ? `$${parseFloat(avgPricePerPerson).toFixed(2)} ${avgPricePerPersonType}`
      : 'N/A'
  const titleDetails = `${dayString} | ${dateString} | ${headCount} Headcount | ${avgPppString} Target PPH`

  const onInput = (field) => (value) => {
    if (value.target) {
      ;({ value } = value.target)
    }
    onChange({ [field]: value })
  }

  const renderEditDetails = () => {
    return (
      <FlexContainer
        padding="10px 0 0 0"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DateInput
          label="Delivery Date"
          date={clientSetUpTime}
          width="22%"
          onChange={onSelectSetupDate}
          hideClearDate={true}
        />
        <TimeInput
          width="22%"
          label="Setup Complete By"
          time={clientSetUpTime}
          onChange={onInput('clientSetUpTime')}
        />
        <Input
          label="# of People" // Price
          marginBottom="0"
          type="number"
          width="22%"
          error={errors.headCount}
          value={headCount}
          onChange={onInput('headCount')}
        />
        <b>{`| ${avgPppString} Target PPH `}</b>
        <XSpacing width="2px" />
        <button
          style={{ display: 'flex', flexDirection: 'row' }}
          onClick={() => setIsEditDetails(!isEditDetails)}
        >
          <IoCloseOutline />
          <b className="details">Close</b>
        </button>
      </FlexContainer>
    )
  }

  return (
    <FlexContainer flexDirection="column" height="100%">
      <FlexContainer
        flexDirection="row"
        height="100%"
        justifyContent="space-between"
        padding="0 0 0 10px"
      >
        <div
          style={{
            width: '58%',
            borderRight: `${colors.gray200} 1px solid`,
            paddingRight: '20px',
          }}
        >
          <FlexContainer width="100%" flexDirection="column">
            <FlexContainer
              width="100%"
              padding="0 0 20px 0"
              flexDirection="column"
            >
              <OrderFormSectionTitle>
                <span>3</span>
                {chefs && chefs.length > 0
                  ? 'Review Your Menu'
                  : 'Build Custom Menu'}
              </OrderFormSectionTitle>
              {isEditDetails ? (
                renderEditDetails()
              ) : (
                <>
                  {!clientSetUpTime ? (
                    <div>
                      Please select a delivery date to see available chefs
                    </div>
                  ) : (
                    <FlexContainer flexDirection="row" padding="10px 0 0 0">
                      <b className="details">{titleDetails}</b>
                      <XSpacing width="5px" />
                      <button
                        style={{ display: 'flex', flexDirection: 'row' }}
                        onClick={() => setIsEditDetails(!isEditDetails)}
                      >
                        <FiEdit2 />
                        <b className="details">Edit</b>
                      </button>
                    </FlexContainer>
                  )}
                </>
              )}
            </FlexContainer>
            <FlexContainer width="100%" justifyContent="space-between">
              {clientSetUpTime && (
                <AutocompleteInput
                  label="Search &amp; Add Chef"
                  width="48%"
                  error={errors.chef}
                  loaderFunction={loadChefs({
                    hasCateringMenuItems: true,
                    clientSetUpTime,
                    orderId: orderableType == 'Order' ? id : null,
                    proposalId: orderableType == 'Proposal' ? id : null,
                    orderType,
                    headquarterId,
                  })}
                  placeholder="Select Chef / Vendor"
                  onSelect={onSelectChef}
                />
              )}
              {!isVCX && !isSnackPack && clientSetUpTime && (
                <LabelInfo
                  width="48%"
                  label="Concept Name"
                  value={conceptName || 'None'}
                />
              )}
            </FlexContainer>
            {chefs.map((chef, index) =>
              renderChef({
                chef,
                isFirst: index === 0,
                isLast: index === chefs.length - 1,
              }),
            )}
          </FlexContainer>
        </div>
        <FlexContainer width="38%" flexDirection="column">
          <OrderFormSectionTitle margin="0 0 20px 0">
            <span>4</span> Review Order Margin, Price Per head & Total
          </OrderFormSectionTitle>
          <TotalSection
            orderSettings={orderSettings}
            clientSetUpTime={clientSetUpTime}
            adjustedChefPayouts={adjustedChefPayouts}
            dropoffAddress={dropoffAddress}
            chefs={chefs}
            createdAt={createdAt}
            numChefs={chefs.length}
            discount={discount}
            discounts={discounts}
            headCount={headCount}
            initialBalances={initialBalances}
            isGratuityOverride={isGratuityOverride}
            isDeliveryFeeOverride={isDeliveryFeeOverride}
            isStaffingFeeOverride={isStaffingFeeOverride}
            isTaxExempt={isTaxExempt}
            isPaid={isPaid}
            serviceType={serviceType}
            isVCX={isVCX}
            needsCleanup={needsCleanup}
            needsStaffing={needsStaffing}
            orderId={id}
            orderServiceCost={orderServiceCost || {}}
            orderType={orderType}
            servicesBreakdown={servicesBreakdown}
            deliveryFee={deliveryFee}
            cleanupFee={cleanupFee}
            staffingFee={staffingFee}
            numberOfStaff={numberOfStaff}
            staffingHours={staffingHours}
            staffingRate={staffingRate}
            serviceFee={serviceFee}
            subtotal={subtotal}
            predictedServiceCosts={predictedServiceCosts}
            supplyBreakdown={supplyBreakdown}
            tax={tax}
            taxRecord={taxRecord}
            taxRates={taxRates}
            deliveryTaxRates={deliveryTaxRates}
            servicesTaxRates={servicesTaxRates}
            alcoholTaxRates={alcoholTaxRates}
            taxExemptCause={orderTaxExemptCause}
            tip={tip}
            tipLocked={tipLocked}
            carbonNeutral={carbonNeutral}
            carbonNeutralContribution={carbonNeutralContribution}
            total={total}
            zipcode={taxZip}
            deliveryFeePercent={deliveryFeePercent}
            deliveryFeeLimit={deliveryFeeLimit}
            defaultTipPercent={defaultTipPercent}
            onChange={onChange}
            updateServiceAndSupplyCosts={updateServiceAndSupplyCosts}
            serviceInputsChanged={serviceInputsChanged}
            routingInputsChanged={routingInputsChanged}
          />
        </FlexContainer>
      </FlexContainer>

      <YSpacing height="20px" />

      <FlexContainer flexDirection="row" justifyContent="space-between">
        <FlexContainer width="58%" flexDirection="row">
          <FlexContainer alignItems="center" justifyContent="flex-start">
            <FlexContainer flexDirection="column">
              {chefs.length > 1 && (
                <div>
                  <Button
                    testId="delete"
                    label={'〈 Delete all vendors and start over'}
                    onClick={() => onDeleteAllChefs()}
                    backgroundColor={colors.orange}
                  />
                  <YSpacing height="10px" />
                </div>
              )}
              <Button
                testId="save"
                label={'〈 Update Order Details'}
                onClick={navigateToPreviousSection}
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer width="38%" flexDirection="column" alignItems="end">
          <FlexContainer alignItems="center" justifyContent="flex-end">
            <TechHelpForm margin="0 30px 0 0" />
            <LinkText
              testId="cancel"
              label="Cancel"
              onClick={onHide}
              color={colors.violet}
            />
            <XSpacing width="20px" />
            <AuthorizedInteractable roles={saveOrderRoles}>
              <Button
                testId="save-order"
                label={`Save ${orderableType}`}
                onClick={onSave}
              />
            </AuthorizedInteractable>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

EditOrderSection3.propTypes = {
  orderable: OrderablePropType,
  orderSettings: PropTypes.object,
  errors: PropTypes.object,
  headquarterId: PropTypes.number,
  previousSection: PropTypes.string,
  saveOrderRoles: PropTypes.array,

  loadChefs: PropTypes.func,
  renderChef: PropTypes.func,
  onSelectChef: PropTypes.func,
  navigateToSection: PropTypes.func,
  onSelectSetupDate: PropTypes.func,
  onChange: PropTypes.func,
  onDeleteAllChefs: PropTypes.func,
  updateServiceAndSupplyCosts: PropTypes.func,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
}

export default EditOrderSection3
