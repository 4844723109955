import React, { useState, useEffect, ReactNode } from 'react'
import {
  useExternalStoreRuntime,
  ThreadMessageLike,
  AppendMessage,
  AssistantRuntimeProvider,
  ToolCallContentPart,
} from '@assistant-ui/react'
import { Chat, ChatRole, UserChat } from './types'
import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

const { AiApiService } = services
const { AddUserChatMessage, CreateUserChat } = coordinators

const addUserChatMessage = AddUserChatMessage({ AiApiService, HandleError })
const createUserChat = CreateUserChat({ AiApiService, HandleError })

const convertMessage = (message: Chat): ThreadMessageLike => {
  const role =
    message.role === ChatRole.User ? ChatRole.User : ChatRole.Assistant

  if (message.additionalKwargs?.toolCalls?.length) {
    // This is a message preparing to make tool calls
    return {
      role: ChatRole.Assistant,
      content: message.additionalKwargs.toolCalls.map(
        (toolCall: any) =>
          ({
            type: 'tool-call',
            toolCallId: toolCall.id,
            toolName: toolCall.function?.name || toolCall.name || '?',
            args: toolCall.function?.arguments || toolCall.input || '{}',
            argsText:
              toolCall.function?.arguments ||
              (toolCall.input && JSON.stringify(toolCall.input, null, 2)) ||
              '',
            result: message.blocks
              .filter((block) => block.blockType === 'text')
              .map((block) => block.text)
              .join('\n\n'),
            display: 'preparing',
          }) as ToolCallContentPart,
      ),
    }
  } else if (message.role === ChatRole.Tool) {
    // This is a tool response message
    return {
      role: ChatRole.Assistant,
      content: [
        {
          type: 'tool-call',
          toolCallId: message.additionalKwargs?.toolCallId || 'unknown',
          toolName: message.additionalKwargs?.name || 'unknown',
          args: {},
          argsText: '{}',
          result: message.blocks
            .filter((block) => block.blockType === 'text')
            .map((block) => block.text)
            .join('\n\n'),
          display: 'complete',
        } as ToolCallContentPart,
      ],
    }
  } else {
    return {
      role,
      content: message.blocks
        .filter((block) => block.blockType === 'text')
        .map((block) => ({
          type: 'text',
          text: block.text,
        })),
    }
  }
}

export function HungryRuntimeProvider({
  userId,
  userChatId,
  chatHistory,
  children,
  llmModel,
  temperature,
  topP,
  maxTokens,
  onAddUserChat,
}: Readonly<{
  userId: string
  userChatId: number | null
  chatHistory: Chat[]
  children: ReactNode
  llmModel: string
  topP: number
  temperature: number
  maxTokens: number
  onAddUserChat: (newUserChat: UserChat) => void
}>) {
  const [isRunning, setIsRunning] = useState(false)
  const [messages, setMessages] = useState<Chat[]>([])

  useEffect(() => {
    setMessages(chatHistory)
  }, [chatHistory])

  const onNew = async (message: AppendMessage) => {
    if (message.content[0]?.type !== 'text') {
      throw new Error('Only text messages are supported')
    }

    const input = message.content[0].text
    setMessages((currentConversation) => [
      ...currentConversation,
      {
        role: ChatRole.User,
        blocks: [{ blockType: 'text', text: input, role: 'user' }],
      },
    ])

    setIsRunning(true)
    let chatMessages: Chat[] = []
    if (userChatId) {
      const chatResponse: Chat[] = await addUserChatMessage({
        userId,
        userChatId,
        llmModel,
        temperature,
        topP,
        maxTokens,
        chatText: input,
      })
      if (!chatResponse) {
        setIsRunning(false)

        return
      }
      chatMessages = chatResponse
    } else {
      const chatResponse = await createUserChat({
        userId,
        llmModel,
        temperature,
        topP,
        maxTokens,
        chatText: input,
      })
      if (!chatResponse) {
        setIsRunning(false)

        return
      }
      const {
        chatMessages: newChatMessages,
        userChat,
      }: { chatMessages: Chat[]; userChat: UserChat } = chatResponse
      chatMessages = newChatMessages
      onAddUserChat(userChat)
    }
    setMessages((currentConversation) => [
      ...currentConversation,
      ...chatMessages,
    ])
    setIsRunning(false)
  }

  const runtime = useExternalStoreRuntime({
    isRunning,
    messages,
    convertMessage,
    onNew,
  })

  return (
    <AssistantRuntimeProvider runtime={runtime}>
      {children}
    </AssistantRuntimeProvider>
  )
}
