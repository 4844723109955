import { BaseService } from 'hungry-core2'

import Api from './aiApi/index'

export class AiApiService extends BaseService {
  async get(uri, params = {}, { headers, timeout } = {}) {
    return await Api.get(uri, params, timeout, headers)
  }

  async post(uri, data = {}, { headers, timeout } = {}) {
    return await Api.post(uri, data, timeout, headers)
  }

  async put(uri, data = {}, { headers, timeout } = {}) {
    return await Api.put(uri, data, timeout, headers)
  }

  async delete(uri, data = {}, { headers, timeout } = {}) {
    return await Api.delete(uri, data, timeout, headers)
  }
}
