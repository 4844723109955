import axios from './axios'

export const get = function (uri, params, timeout, headers) {
  /* encode params */
  let queryString = ''
  const keys = Object.keys(params)
  if (keys.length === 1) {
    queryString += `?${encodeURI(keys[0])}=${encodeURI(params[keys[0]])}`
  } else if (keys.length > 1) {
    queryString += `?${encodeURI(keys[0])}=${encodeURI(params[keys[0]])}`
    for (const key of keys.slice(1)) {
      queryString += `&${encodeURI(key)}=${encodeURI(params[key])}`
    }
  }

  const req = {
    method: 'get',
    url: uri + queryString,
  }
  if (timeout) {
    req.timeout = timeout
  }
  if (headers) {
    req.headers = headers
  }

  return axios.request(req)
}

export const post = function (uri, data, timeout, headers) {
  const req = {
    method: 'post',
    url: uri,
    data: data,
  }
  if (timeout) {
    req.timeout = timeout
  }
  if (headers) {
    req.headers = headers
  }

  return axios.request(req)
}

export const put = function (uri, data, timeout, headers) {
  const req = {
    method: 'put',
    url: uri,
    data: data,
  }
  if (timeout) {
    req.timeout = timeout
  }
  if (headers) {
    req.headers = headers
  }

  return axios.request(req)
}

const delete_ = function (uri, data, timeout, headers) {
  const req = {
    method: 'delete',
    data: data,
    url: uri,
  }
  if (timeout) {
    req.timeout = timeout
  }
  if (headers) {
    req.headers = headers
  }

  return axios.request(req)
}

export { delete_ as delete }
