import { connect } from 'react-redux'

import { DinerProfilesSection } from '@components/order/edit/sections'

import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (_: any) => ({})

const mapDispatchToProps = () => {
  const { RestService, AccountService } = services
  const { LoadDinerProfiles, LoadClientReportingPrograms } = coordinators

  const loadDinerProfiles = LoadDinerProfiles({
    RestService,
    HandleError,
  })

  const loadClientReportingPrograms = LoadClientReportingPrograms({
    RestService,
    AccountService,
    HandleError,
  })

  return {
    loadDinerProfiles,
    loadClientReportingPrograms,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DinerProfilesSection)
