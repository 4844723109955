import { connect } from 'react-redux'
import { ReportingPrograms } from '@components/account/accountSections'
import coordinators from '@coordinators'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import {
  ClientReportingProgram,
  Account,
  LoadClientReportingProgramsParams,
} from '@types'

interface State {
  account: Account
  user: { name: string }
  clientReportingPrograms: ClientReportingProgram[]
}

const mapStateToProps = (state: State) => {
  const { account, clientReportingPrograms, user } = state

  return {
    account,
    currentUser: user,
    clientReportingPrograms,
  }
}

const mapDispatchToProps = () => {
  const { RestService, AccountService, UIService } = services
  const {
    LoadClientReportingPrograms,
    EditClientReportingProgram,
    LoadDinerProfiles,
  } = coordinators

  const loadClientReportingPrograms = LoadClientReportingPrograms({
    RestService,
    AccountService,
    HandleError,
  }) as (
    params: LoadClientReportingProgramsParams,
  ) => Promise<ClientReportingProgram[]>

  const loadDinerProfiles = LoadDinerProfiles({
    RestService,
    HandleError,
  })

  const editClientReportingProgram = EditClientReportingProgram({
    AccountService,
    UIService,
  })

  return {
    loadClientReportingPrograms,
    loadDinerProfiles,
    editClientReportingProgram,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPrograms)
