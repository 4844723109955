import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextSelectInput from './TextSelectInput'
import FlexContainer from '@components/common/FlexContainer'
import { FormInputContainer } from '@res/styledComponents/index'
import styled from '@emotion/styled'

class AutocompleteInput extends Component {
  state = {
    options: [],
    value: '',
    showLoader: false,
  }
  searchTimer = undefined

  componentDidMount() {
    const { value } = this.props
    this.setState({ value })
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (this.props.value !== prevProps.value) {
      this.setState({ value, options: [] })
    } else if (this.props.loaderFunction !== prevProps.loaderFunction) {
      this.setState({ options: [] })
    }
  }

  doAfterTimeout = (func) => {
    const { searchDelay } = this.props
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      func()
    }, searchDelay)
  }

  loadOptions = (value) => async () => {
    const { filters, includePagination = true } = this.props
    this.setState({ showLoader: true })
    const req = {
      ...filters,
      search: value || '',
    }
    if (includePagination) {
      req.page = 1
      req.results_per_page = 15
    }
    const options = await this.props.loaderFunction(req)
    const newState = { showLoader: false }
    if (options) {
      newState.options = options
    }
    this.setState(newState)
  }

  refreshOptions = (value) => {
    this.setState({ value })
    this.doAfterTimeout(this.loadOptions(value))
  }

  onInput = (value) => {
    this.refreshOptions(value)

    this.props.onInput && this.props.onInput(value)
  }

  onSelect = (option) => {
    if (this.props.refreshOnSelect) {
      this.refreshOptions(option.name)
    }
    this.props.onSelect(option)
  }

  onFocus = () => {
    if (this.props.clearOnFocus) {
      this.setState({ value: '' }, () => {
        this.refreshOptions(this.state.value)
      })
    } else {
      this.refreshOptions(this.state.value)
    }
  }

  render() {
    const {
      alreadySelectedIDs,
      defaultOptions,
      disabled,
      displayAttribute,
      displayAttributes,
      error,
      placeholder,
      label,
      renderAdditionalLabels,
      width,
      onClear,
      optionsHeight,
      optionRenderFunction,
      testId,
    } = this.props
    const { options, value, showLoader } = this.state
    let newOptions = [...defaultOptions, ...options]
    if (alreadySelectedIDs.length > 0) {
      // remove already selected items
      newOptions = options.filter(
        (option) => !alreadySelectedIDs.includes(option.id),
      )
    }

    return (
      <FormInputContainer width={width} id={testId}>
        <FlexContainer>
          <label>{label}</label>
          {renderAdditionalLabels && renderAdditionalLabels()}
        </FlexContainer>
        <div style={{ position: 'relative', width: '100%' }}>
          <TextSelectInput
            label={label}
            disabled={disabled}
            displayAttribute={displayAttribute}
            displayAttributes={displayAttributes}
            error={error}
            options={newOptions}
            value={value}
            optionsHeight={optionsHeight}
            showLoader={showLoader}
            placeholder={placeholder}
            onInput={this.onInput}
            onSelect={this.onSelect}
            onFocus={this.onFocus}
            optionRenderFunction={optionRenderFunction}
          />
          {onClear && <ClearInput onClick={onClear}>X</ClearInput>}
        </div>
      </FormInputContainer>
    )
  }
}

export const ClearInput = styled.button`
  position: absolute;
  right: 10px;
  top: 7px;
`

AutocompleteInput.propTypes = {
  alreadySelectedIDs: PropTypes.array,
  clearOnFocus: PropTypes.bool,
  defaultOptions: PropTypes.array,
  displayAttribute: PropTypes.string,
  displayAttributes: PropTypes.array,
  error: PropTypes.string,
  includePagination: PropTypes.bool,
  options: PropTypes.array,
  optionsHeight: PropTypes.string,
  placeholder: PropTypes.string,
  refreshOnSelect: PropTypes.bool,
  searchDelay: PropTypes.number,
  renderAdditionalLabels: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  testId: PropTypes.string,

  filters: PropTypes.object,
  callAfterTimeout: PropTypes.func,
  loaderFunction: PropTypes.func,
  optionRenderFunction: PropTypes.func,
  onClear: PropTypes.func,
  onInput: PropTypes.func,
  onSelect: PropTypes.func,
}

AutocompleteInput.defaultProps = {
  clearOnFocus: true,
  optionsHeight: '40',
  defaultOptions: [],
  displayAttribute: 'name',
  includePagination: true,
  placeholder: '',
  refreshOnSelect: true,
  searchDelay: 550,
  alreadySelectedIDs: [],
  filters: {},
  disabled: false,
}

export default AutocompleteInput
