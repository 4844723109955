import { DISPLAY_INFO_MODAL, DISMISS_INFO_MODAL } from '@actions/ui/infoModal'

export const infoModal = (state = { show: false }, action) => {
  switch (action.type) {
    case DISPLAY_INFO_MODAL: {
      const { title, content } = action

      return { title, content, show: true }
    }
    case DISMISS_INFO_MODAL: {
      return { show: false }
    }
    default:
      return state
  }
}
