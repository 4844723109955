import { connect } from 'react-redux'

import InfoModal from '@components/common/modal/InfoModal'

import services from '@services'

const mapStateToProps = (state) => {
  const { infoModal } = state
  const { show, title, content, close } = infoModal

  return {
    show,
    title,
    content,
    close,
  }
}

const mapDispatchToProps = () => {
  const { UIService } = services
  const close = UIService.InfoModal.dismissInfoModal

  return { close }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal)
