import { connect } from 'react-redux'

import { PlaygroundPage } from '@components/cateringConcierge'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { camelCaseify } from '~/utils'

const mapStateToProps = (state) => {
  const { user, router } = state

  return {
    router,
    userId: user && user.id,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService, AiApiService } = services
  const {
    pResponseAccounts,
    pResponseSurveyObjects,
    pRequestOrdersFilter,
    pRequestSurveys,
  } = presenters.Api
  const {
    AddUserChatMessage,
    EditUserChat,
    GetIndexAccountsByIds,
    GetMarketActiveItems,
    GetChatHistory,
    GetUserChats,
    LoadDinerProfiles,
    AsyncLoadSurveys,
    SearchAccountingClients,
    SearchOrders,
  } = coordinators

  const addUserChatMessage = AddUserChatMessage({ AiApiService })
  const editUserChat = EditUserChat({ AiApiService, HandleError })
  const getAccountsByIds = GetIndexAccountsByIds({
    RestService,
    pResponseAccounts,
  })
  const getMarketActiveItems = GetMarketActiveItems({
    RestService,
    pResponseMenuItems: camelCaseify,
  })
  const getChatHistory = GetChatHistory({ AiApiService })
  const getUserChats = GetUserChats({ AiApiService })
  const loadDinerProfiles = LoadDinerProfiles({ RestService, UIService })
  const loadSurveys = AsyncLoadSurveys({
    RestService,
    pResponseSurveyObjects,
    pRequestSurveys,
  })
  const searchAccountingClients = SearchAccountingClients({
    RestService,
    pResponseClients: camelCaseify,
  })
  const searchOrders = SearchOrders({
    RestService,
    pRequestOrdersFilter,
    pResponseOrders: camelCaseify,
    HandleError,
  })
  const showInfoModal = UIService.InfoModal.displayInfoModal
  const showConfirmModal = UIService.ConfirmationModal.show

  return {
    addUserChatMessage,
    editUserChat,
    getAccountsByIds,
    getChatHistory,
    getUserChats,
    getMarketActiveItems,
    loadDinerProfiles,
    loadSurveys,
    searchAccountingClients,
    searchOrders,
    showInfoModal,
    showConfirmModal,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundPage)
