import { Thread } from '@assistant-ui/react'
import React, { useState, useEffect, ChangeEvent } from 'react'
import Moment from 'moment-timezone'
import { ToolCallContentPart } from '@assistant-ui/react'

import { Dropdown, RangeSlider } from '@components/common/form'
import { HungryRuntimeProvider } from './runtimeProvider'
import { MarkdownText } from './markdownText'
import { UserChat, Chat } from './types'

interface Account {
  id: number
  name: string
  clientChampion?: { firstName: string }
  clientHunter?: { firstName: string }
  pin?: string
}

interface DinerProfile {
  id: string
  accountId: number
  name: string
  detailName?: string
}

interface Order {
  orderNumber: string
}

interface Survey {
  results: Array<{ answer: string }>
}

interface Headquarters {
  id: number
  name: string
}

interface EditUserChatReq {
  userId: string
  userChatId: number
  title?: string
  isArchived?: boolean
}

interface ShowInfoModalReq {
  title: string
  content: React.ReactNode
}

const PlaygroundInfo = () => {
  return (
    <div className="flex flex-col items-center tracking-wider">
      <div>
        The Catering Concierge Playground is a chat bot that responds to the
        user&apos;s queries with natural language responses. Chats can be shared
        with other Hungry users by sending them the link to the chat.
      </div>

      <div className="my-4">
        <div className="uppercase underline font-semibold">
          Playground Settings
        </div>
        <div className="my-4">
          Thes playground settings configures the behavior of the chat
          bot&apos;s response.
          <ul className="list-disc pl-6 my-4">
            <li>
              <span className="font-semibold">LLM Model:</span> The underlying
              AI large language model used by the chat bot. Currently only
              supports OpenAI models. More details about them can be found here{' '}
              <a
                className="underline text-blue-700"
                target="_blank"
                href="https://platform.openai.com/docs/models"
                rel="noreferrer"
              >
                OpenAI LLM models
              </a>
            </li>
            <li>
              <span className="font-semibold">Temperature:</span> Controls
              Randomness. Lowering results in less random completions. As the
              temperature approaches zero, the model becomes deterministic and
              repetitive.
            </li>
            <li>
              <span className="font-semibold">Max Tokens:</span> The max number
              of tokens to generate shared between the prompt and completion.
              The exact limit varies by model. One token is roughly 4 characters
              for standard English text.
            </li>
            <li>
              <span className="font-semibold">Top P:</span> Controls diversity
              via nucleus sampling: 0.5 means half of all likelihood-weighted
              options are considered.
            </li>
          </ul>
        </div>
      </div>

      <div className="my-4">
        <div className="uppercase underline font-semibold">Tools</div>
        <div className="my-4">
          The chat bot has access to the following tools.
          <ul className="list-disc pl-6 my-4">
            <li>Get all Hungry markets</li>
            <li>Search and filter for accounts</li>
            <li>Search and filter for diner profiles</li>
            <li>Search and filter for chefs</li>
            <li>Search and filter for menu items</li>
            <li>Search and filter for catering orders</li>
            <li>List client - chef surveys for an account</li>
          </ul>
        </div>
        <div className="my-4">
          Tools can be API calls to the Hungry servers, calculators, or
          documents, currently all tools are API calls to Hungry servers. The
          chat will display when a tool is being called, what values are being
          passed to the tool, and what the tool&apos;s result is. The key to
          getting the chat bot to be effective is to make sure it calls the
          right tools with the right values.
        </div>
      </div>
    </div>
  )
}

interface Props {
  router: any
  userId: string
  headquarters: Headquarters[]
  editUserChat: (req: EditUserChatReq) => Promise<UserChat>
  getChatHistory: (chatId: number) => Promise<any[]>
  getUserChats: (userChatId: string) => Promise<UserChat[]>
  getMarketActiveItems: (hqId: number) => Promise<any[]>
  loadDinerProfiles: (params: { hqIds: number[] }) => Promise<DinerProfile[]>
  loadSurveys: (params: any) => Promise<Survey[]>
  searchAccountingClients: (params: { ids: number[] }) => Promise<Account[]>
  searchOrders: (params: any) => Promise<Order[]>
  showInfoModal: (req: ShowInfoModalReq) => void
  showConfirmModal: ({ text }: { text: string }) => Promise<boolean>
}

// OpenAI models
// const LLMModels = [
//   'gpt-4o-2024-08-06',
//   'chatgpt-4o-latest',
//   'gpt-4o-mini-2024-07-18',
//   'o1-2024-12-17',
//   'o1-mini-2024-09-12',
//   'o1-preview-2024-09-12',
//   'gpt-3.5-turbo-0125',
//   'gpt-3.5-turbo',
// ]

// Anthropic models
const LLMModels = [
  'claude-3-5-sonnet-20241022',
  'claude-3-5-sonnet-latest',
  'claude-3-5-haiku-20241022',
  'claude-3-5-haiku-latest',
  'claude-3-opus-20240229',
  'claude-3-opus-latest',
]

interface ExpToolCallProps {
  expToolCalls: { [key: string]: boolean }
  setExpToolCalls: (expToolCalls: { [key: string]: boolean }) => void
}

const ToolCallComponent = React.memo(
  (props: ToolCallContentPart & ExpToolCallProps) => {
    const isExpToolCall = props.expToolCalls[props.toolCallId]
    const resultStr = props.result
      ? typeof props.result === 'string'
        ? props.result
        : JSON.stringify(props.result, null, 2)
      : null

    return (
      <div className={`bg-gray-50 rounded-lg p-4 my-2`}>
        <div className="flex items-center">
          <button
            onClick={() => {
              props.setExpToolCalls({
                ...props.expToolCalls,
                [props.toolCallId]: !isExpToolCall,
              })
            }}
            className="mr-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label={isExpToolCall ? 'Collapse' : 'Expand'}
          >
            {isExpToolCall ? '▼' : '▶'}
          </button>
          <div className="font-medium text-gray-700">
            Tool Call: {props.toolName}
          </div>
        </div>
        {isExpToolCall && (
          <>
            <div className="mt-2">
              <div className="text-sm text-gray-500">Arguments:</div>
              <pre className="mt-1 text-sm bg-gray-100 p-2 rounded">
                {props.argsText}
              </pre>
            </div>
            {resultStr && (
              <div className="mt-2">
                <div className="text-sm text-gray-500">Result:</div>
                <pre className="mt-1 text-sm bg-gray-100 p-2 rounded whitespace-pre-wrap">
                  {resultStr}
                </pre>
              </div>
            )}
          </>
        )}
      </div>
    )
  },
)

const PlaygroundPage: React.FC<Props> = ({
  router,
  userId,
  editUserChat,
  getChatHistory,
  getUserChats,
  showInfoModal,
  showConfirmModal,
}) => {
  const [selectedLLMModel, setSelectedLLMModel] = useState(LLMModels[0])
  const [maxTokens, setMaxTokens] = useState(2048)
  const [topP, _] = useState(0.0)
  const [temperature, setTemperature] = useState(0.1)
  const [userChatId, setUserChatId] = useState<number | null>(null)
  const [chatHistory, setChatHistory] = useState<Chat[]>([])
  const [userChats, setUserChats] = useState<UserChat[]>([])
  const [editUserChatReq, setEditUserChatReq] =
    useState<EditUserChatReq | null>(null)
  const [hoverUserChatId, setHoverUserChatId] = useState<number | null>(null)
  const [expToolMsgs, setExpToolMsgs] = useState<{ [key: string]: boolean }>({})

  const setUrlChatId = (nextUserChatId: number) => {
    const params = new URLSearchParams(window.location.search)
    params.set('userChatId', nextUserChatId.toString())
    const newUrl = `${window.location.pathname}?${params.toString()}`
    window.history.pushState({}, '', newUrl)
  }

  const onLoadUserChatHistory = async (userChatId: number) => {
    const chatHistory = await getChatHistory(userChatId)
    setChatHistory(chatHistory)
  }

  // react router location only triggers when page loads so this only triggers on nav
  useEffect(() => {
    const params = new URLSearchParams(router.location.search)
    const userChatIdStr = params.get('userChatId')
    const userChatId = Number(userChatIdStr)
    if (!isNaN(userChatId) && userChatId > 0) {
      setUserChatId(userChatId)
      onLoadUserChatHistory(userChatId)
      setExpToolMsgs({})
    }
  }, [router.location.search])

  useEffect(() => {
    const loadUserChats = async () => {
      let userChats = await getUserChats(userId)
      userChats = userChats.sort((a, b) =>
        Moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1,
      )
      setUserChats(userChats)
    }

    loadUserChats()
  }, [])

  const onNewUserChat = () => {
    setUserChatId(null)
    setChatHistory([])
    setExpToolMsgs({})
  }

  const onSelectUserChat = (userChat: UserChat) => () => {
    if (userChatId === userChat.id) {
      return
    }
    setUrlChatId(userChat.id)
    setUserChatId(userChat.id)
    onLoadUserChatHistory(userChat.id)
  }

  const onAddUserChat = (newUserChat: UserChat) => {
    setUserChats([newUserChat, ...userChats])
    setUrlChatId(newUserChat.id)
    setUserChatId(newUserChat.id)
  }

  const onEditUserChatTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (!userChatId) {
      return
    }
    const nextEditUserChatReq = { userId, userChatId, title: e.target.value }
    setEditUserChatReq(nextEditUserChatReq)
  }

  const onSaveUserChatEdit = (idx: number) => async () => {
    if (!editUserChatReq) {
      return
    }
    const nextUserChat = await editUserChat(editUserChatReq)
    const nextUserChats = userChats.slice()
    nextUserChats[idx] = nextUserChat
    setUserChats(nextUserChats)
    setEditUserChatReq(null)
  }

  const onArchiveUserChat = (userChat: UserChat, idx: number) => async () => {
    const doArchive = await showConfirmModal({
      text: `Are you sure you want to archive chat '${userChat.title}'?`,
    })
    if (doArchive) {
      const nextUserChat = await editUserChat({
        userId,
        userChatId: userChat.id,
        isArchived: true,
      })
      if (nextUserChat && nextUserChat.isArchived) {
        setUserChats([...userChats.slice(0, idx), ...userChats.slice(idx + 1)])
        if (userChatId === userChat.id) {
          onNewUserChat()
        }
      }
    }
  }

  return (
    <div className="min-h-screen w-screen grid grid-rows-[auto_1fr] rounded-lg border-2 border-gray-300 border-solid shadow my-10 mx-10">
      <HungryRuntimeProvider
        userId={userId}
        userChatId={userChatId}
        llmModel={selectedLLMModel}
        chatHistory={chatHistory}
        temperature={temperature}
        topP={topP}
        maxTokens={maxTokens}
        onAddUserChat={onAddUserChat}
      >
        <header className="bg-gray-100 px-3 py-2 border-2 border-gray-300 border-solid">
          <div className="flex flex-row">
            <span className="font-semibold text-lg">Playground Settings</span>
            <button
              className="w-6 h-6 flex items-center justify-center bg-green-300 text-white text-xl font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-4"
              aria-label="Info"
              title="Info"
              onClick={() =>
                showInfoModal({
                  title: 'Playground Info',
                  content: <PlaygroundInfo />,
                })
              }
            >
              ℹ
            </button>
          </div>
          <div className="flex flex-row items-center gap-x-6 my-4">
            <Dropdown
              label="LLM Model"
              width="200px"
              value={selectedLLMModel}
              onChange={(e) => setSelectedLLMModel(e.target.value)}
            >
              <option value="">No Filter</option>
              {LLMModels.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </Dropdown>

            <RangeSlider
              label="Temperature"
              min={0}
              max={1}
              step={0.05}
              initialValue={0.1}
              onChange={(nextTemp) => setTemperature(nextTemp)}
            />

            {/* <RangeSlider
              label="Top P"
              min={0}
              max={20}
              step={1}
              initialValue={0}
              onChange={(nextTopP) => setTopP(nextTopP)}
            /> */}

            <RangeSlider
              label="Max Tokens"
              min={0}
              max={16383}
              step={1}
              initialValue={2048}
              onChange={(nextMaxTokens) => setMaxTokens(nextMaxTokens)}
            />
          </div>
        </header>

        <div className="grid grid-cols-[25%_75%]">
          <aside className="bg-gray-100 px-3 py-2 border-2 border-gray-300 border-solid font-medium">
            <div
              className="rounded border-2 border-gray-400 border-solid w-full pointer p-2 bg-gray-200 hover:bg-gray-400"
              onClick={onNewUserChat}
            >
              + New Chat
            </div>
            {userChats.map((userChat, idx) => {
              const isEditing = editUserChatReq?.userChatId === userChat.id
              const isHovering = hoverUserChatId === userChat.id
              const isSelected = userChatId === userChat.id

              return (
                <div
                  className={`flex items-center justify-center rounded border-2 border-gray-400 border-solid w-full pointer p-2 my-2 ${
                    userChatId === userChat.id ? 'bg-gray-400' : 'bg-gray-200'
                  } hover:bg-gray-400`}
                  key={userChat.id}
                  onClick={onSelectUserChat(userChat)}
                  onMouseEnter={() => setHoverUserChatId(userChat.id)}
                  onMouseLeave={() => setHoverUserChatId(null)}
                >
                  <div className="mr-auto">
                    {Moment(userChat.createdAt).format('lll')}
                  </div>
                  <input
                    type="text"
                    className={`mr-auto rounded p-1 border border-1 border-solid ${
                      isSelected ? 'bg-gray-400' : 'bg-gray-200'
                    } ${
                      isHovering
                        ? `border-gray-100`
                        : isSelected
                          ? 'border-gray-400'
                          : 'border-gray-200'
                    }`}
                    value={isEditing ? editUserChatReq.title : userChat.title}
                    onChange={onEditUserChatTitle}
                  />
                  {isEditing && (
                    <button
                      className="w-6 h-6 flex items-center justify-center bg-green-300 text-white font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-auto"
                      aria-label="Cancel"
                      title="Cancel"
                      onClick={() => setEditUserChatReq(null)}
                    >
                      x
                    </button>
                  )}
                  {isEditing && (
                    <button
                      className="w-6 h-6 flex items-center justify-center bg-green-300 text-white font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-auto"
                      aria-label="Save"
                      title="Info"
                      onClick={onSaveUserChatEdit(idx)}
                    >
                      ✓
                    </button>
                  )}
                  {isHovering && !isEditing && (
                    <button
                      className="w-6 h-6 flex items-center justify-center bg-green-300 text-white font-extrabold rounded-full shadow hover:bg-white hover:text-blue-300 focus:outline-none focus:ring focus:ring-blue-300 ml-auto"
                      aria-label="Archive"
                      title="Archive"
                      onClick={onArchiveUserChat(userChat, idx)}
                    >
                      🗑
                    </button>
                  )}
                </div>
              )
            })}
          </aside>

          <main className="bg-white px-3 py-2 border-2 border-gray-300 border-solid overflow-hidden">
            <Thread
              assistantMessage={{
                components: {
                  Text: MarkdownText,
                  ToolFallback: (props) => (
                    <ToolCallComponent
                      {...props}
                      expToolCalls={expToolMsgs}
                      setExpToolCalls={setExpToolMsgs}
                    />
                  ),
                },
              }}
            />
          </main>
        </div>
      </HungryRuntimeProvider>
    </div>
  )
}

export default PlaygroundPage
