import React, { useEffect, useMemo, useCallback, useState } from 'react'
import Moment from 'moment-timezone'

import { Button } from '@components/common/form'
import Table from '@components/common/Table'

import {
  ClientReportingProgram,
  ApiDinerProfile,
  LoadClientReportingProgramsParams,
} from '@types'

import { colors } from '../../../../constants'

interface ReportingProgramsProps {
  account: { id: string }
  clientReportingPrograms: ClientReportingProgram[]
  loadClientReportingPrograms: (
    params: LoadClientReportingProgramsParams,
  ) => Promise<ClientReportingProgram[]>
  loadDinerProfiles: (
    params: Record<string, string | string[] | number[] | null>,
  ) => Promise<ApiDinerProfile[]>
  editClientReportingProgram: (reportingProgram: ClientReportingProgram) => void
}

const ReportingPrograms: React.FC<ReportingProgramsProps> = ({
  account,
  clientReportingPrograms,

  loadClientReportingPrograms,
  loadDinerProfiles,
  editClientReportingProgram,
}) => {
  const [dinerProfiles, setDinerProfiles] = useState<ApiDinerProfile[]>([])

  useEffect(() => {
    const { id } = account

    const loadReportingPrograms = async () => {
      await loadClientReportingPrograms({ clientId: id })
    }

    loadReportingPrograms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const dinerProfilesIds = useMemo(() => {
    const uniqueIds = new Set<string>()

    clientReportingPrograms.forEach((reportingProgram) => {
      reportingProgram.dinerProfileIds.forEach((id) => {
        uniqueIds.add(id)
      })
    })

    return Array.from(uniqueIds)
  }, [clientReportingPrograms])

  const _loadDinerProfiles = useCallback(async () => {
    const profiles = await loadDinerProfiles({ ids: dinerProfilesIds })
    setDinerProfiles(profiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dinerProfilesIds])

  useEffect(() => {
    _loadDinerProfiles()
  }, [_loadDinerProfiles])

  const onAddNewReportingProgram = () => {
    editClientReportingProgram({
      clientId: account.id,
      dinerProfileIds: [] as string[],
    } as ClientReportingProgram)
  }

  const onEditReportingProgram = (reportingProgram: ClientReportingProgram) => {
    editClientReportingProgram({ ...reportingProgram })
  }

  return (
    <div>
      <div className="mb-5">
        <Button
          testId={'add-new-reporting-program'}
          label="Add New Reporting Program"
          backgroundColor={colors.gray400}
          onClick={onAddNewReportingProgram}
        />
      </div>
      <div className="w-full p-2 rounded-lg bg-white">
        <Table>
          <tr>
            <th>Reporting Program Name</th>
            <th>Diner Profiles</th>
            <th>Is Default?</th>
            <th>Disabled?</th>
            <th>Disabled At</th>
            <th>Last Updated By</th>
          </tr>
          {clientReportingPrograms.map((reportingProgram) => (
            <ReportingProgramRow
              key={reportingProgram.id}
              dinerProfiles={dinerProfiles.filter((profile) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                reportingProgram.dinerProfileIds.includes(profile.id!),
              )}
              reportingProgram={reportingProgram}
              onEdit={onEditReportingProgram}
            />
          ))}
        </Table>
      </div>
    </div>
  )
}

interface ReportingProgramRowProps {
  reportingProgram: ClientReportingProgram
  dinerProfiles: ApiDinerProfile[]
  onEdit: (reportingProgram: ClientReportingProgram) => void
}

const ReportingProgramRow: React.FC<ReportingProgramRowProps> = ({
  reportingProgram,
  dinerProfiles,
  onEdit,
}) => (
  <tr className="cursor-pointer" onClick={() => onEdit(reportingProgram)}>
    <td>{reportingProgram.name}</td>
    <td>
      {dinerProfiles.length > 0 ? (
        dinerProfiles.map((profile, i) => (
          <p key={`diner-profile-${i}`}>{profile.name}</p>
        ))
      ) : (
        <p>No Diner Profiles Added</p>
      )}
    </td>
    <td>{reportingProgram.isDefault ? 'Yes' : 'No'}</td>
    <td>{reportingProgram.disabled ? 'Yes' : 'No'}</td>
    <td>
      {reportingProgram.disabled && reportingProgram.disabledAt
        ? Moment(reportingProgram.disabledAt).format('MM/DD/YYYY h:mm a')
        : 'N/A'}
    </td>
    <td>
      <p>{reportingProgram.lastUpdatedBy || 'Unknown'}</p>
      <p>{Moment(reportingProgram.updatedAt).format('MM/DD/YYYY h:mm a')}</p>
    </td>
  </tr>
)

export default ReportingPrograms
