import { reducer as sematable } from 'sematable'

import * as accountPageUIReducers from './accountPage'
import * as accountsReceivableUIReducers from './accountsReceivable'
import * as alertUIReducers from './alert'
import * as auditHistoryUIReducers from './auditHistory'
import * as batchOrderPageUIReducers from './batchOrderPage'
import * as calcChefFeesModal from './calcChefFeesModal'
import * as chefPageUIReducers from './chefPage'
import * as confirmationUIReducers from './confirmation'
import * as dashboardUIReducers from './dashboard'
import * as infoModalUIReducers from './infoModal'
import * as errorsUIReducers from './errors'
import * as flashMessageUIReducers from './flashMessage'
import * as resultsMessageModalUIReducers from './resultsMessageModal'
import * as loadingIndicatorUIReducers from './loadingIndicator'
import * as marketPageUIReducers from './marketPage'
import * as orderPageUIReducers from './orderPage'
import * as proposalDashboardReducers from './proposalDashboard'
import * as proposalPageUIReducers from './proposalPage'
import * as userPageUIReducers from './userPage'

export default {
  ...accountPageUIReducers,
  ...accountsReceivableUIReducers,
  ...alertUIReducers,
  ...auditHistoryUIReducers,
  ...batchOrderPageUIReducers,
  ...calcChefFeesModal,
  ...chefPageUIReducers,
  ...confirmationUIReducers,
  ...dashboardUIReducers,
  ...infoModalUIReducers,
  ...errorsUIReducers,
  ...flashMessageUIReducers,
  ...resultsMessageModalUIReducers,
  ...loadingIndicatorUIReducers,
  ...marketPageUIReducers,
  ...orderPageUIReducers,
  ...proposalDashboardReducers,
  ...proposalPageUIReducers,
  ...userPageUIReducers,
  sematable,
}
