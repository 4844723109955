import {
  CLEAR_ACCOUNT,
  CLEAR_EDIT_ACCOUNT,
  CLEAR_EDIT_ACCOUNT_CONTACT,
  REMOVE_ACCOUNT_FROM_ACCOUNTS,
  SET_ACCOUNT,
  SET_ACCOUNT_CONTACTS,
  SET_ACCOUNTS,
  SET_ACCOUNTS_IN_ACCOUNTS,
  SET_EDIT_ACCOUNT,
  SET_EDIT_ACCOUNT_CONTACT,
  SET_ACCOUNTS_SEARCH,
  UPDATE_EDIT_ACCOUNT,
  UPDATE_EDIT_ACCOUNT_CONTACT,
  SET_EDIT_DINER_PROFILE,
  CLEAR_EDIT_DINER_PROFILE,
  SET_EDIT_CLIENT_REPORTING_PROGRAM,
  CLEAR_EDIT_CLIENT_REPORTING_PROGRAM,
} from '@actions/account'

const initialAccountState = null
const initialAccountsState = []
const initialAccountContactsState = []
const initialEditAccountState = {}
const initialEditAccountContactState = {}
const initialEditDinerProfileState = {}
const initialEditClientReportingProgramState = {}

export function account(state = initialAccountState, action) {
  const { type, account } = action

  switch (type) {
    case SET_ACCOUNT:
      return { ...account }
    case CLEAR_ACCOUNT:
      return { ...initialAccountState }
    default:
      return state
  }
}

export function accounts(state = initialAccountsState, action) {
  const { type, accounts, searchParams } = action

  switch (type) {
    case SET_ACCOUNTS:
      return accounts.slice()
    case SET_ACCOUNTS_IN_ACCOUNTS: {
      const newAccounts = state.slice()
      accounts.forEach((account) => {
        const idx = newAccounts.findIndex((o) => o.id === account.id)
        if (idx !== -1) {
          newAccounts[idx] = { ...account }
        } else {
          newAccounts.push(account)
        }
      })

      return newAccounts
    }
    case REMOVE_ACCOUNT_FROM_ACCOUNTS: {
      const newAccounts = state.slice()
      const idx = newAccounts.findIndex((o) => o.id === action.accountId)
      if (idx !== -1) {
        newAccounts.splice(idx, 1)
      }

      return newAccounts
    }
    case SET_ACCOUNTS_SEARCH:
      return {
        ...state,
        searchParams,
      }
    default:
      return state
  }
}

export function accountContacts(state = initialAccountContactsState, action) {
  const { type, accountContacts } = action

  switch (type) {
    case SET_ACCOUNT_CONTACTS:
      return accountContacts.slice()
    default:
      return state
  }
}

export function editAccount(state = initialEditAccountState, action) {
  const { type, account } = action

  switch (type) {
    case SET_EDIT_ACCOUNT:
      return { ...account }
    case UPDATE_EDIT_ACCOUNT:
      return { ...state, ...account }
    case CLEAR_EDIT_ACCOUNT:
      return { ...initialEditAccountState }
    default:
      return state
  }
}

export function editAccountContact(
  state = initialEditAccountContactState,
  action,
) {
  const { type, contact } = action

  switch (type) {
    case SET_EDIT_ACCOUNT_CONTACT:
      return { ...contact }
    case UPDATE_EDIT_ACCOUNT_CONTACT:
      return { ...state, ...contact }
    case CLEAR_EDIT_ACCOUNT_CONTACT:
      return { ...initialEditAccountContactState }
    default:
      return state
  }
}

export function editDinerProfile(state = initialEditDinerProfileState, action) {
  const { type, dinerProfile } = action

  switch (type) {
    case SET_EDIT_DINER_PROFILE:
      return { ...dinerProfile }
    case CLEAR_EDIT_DINER_PROFILE:
      return { ...initialEditDinerProfileState }
    default:
      return state
  }
}

export function editClientReportingProgram(
  state = initialEditClientReportingProgramState,
  action,
) {
  const { type, clientReportingProgram } = action

  switch (type) {
    case SET_EDIT_CLIENT_REPORTING_PROGRAM:
      return { ...clientReportingProgram }
    case CLEAR_EDIT_CLIENT_REPORTING_PROGRAM:
      return { ...initialEditClientReportingProgramState }
    default:
      return state
  }
}
