import { connect } from 'react-redux'

import { EditReportingProgramModal } from '@components/account/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

import {
  ClientReportingProgram,
  Account,
  LoadClientReportingProgramsParams,
} from '@types'

interface State {
  account: Account
  user: { firstName: string; lastName: string }
  editClientReportingProgram: ClientReportingProgram
}

const mapStateToProps = (state: State) => {
  const { account, editClientReportingProgram: program, user } = state

  return {
    account,
    currentUser: user,
    program,
  }
}

const mapDispatchToProps = () => {
  const { RestService, AccountService, UIService, MarketService } = services

  const {
    SaveClientReportingProgram,
    CloseEditClientReportingProgramModal,
    LoadClientReportingPrograms,
    LoadMarkets,
    LoadDinerProfiles,
  } = coordinators

  const { pResponseHeadquarters } = presenters.Api

  const saveClientReportingProgram = SaveClientReportingProgram({
    RestService,
    AccountService,
    UIService,
    HandleError,
  }) as (data: ClientReportingProgram) => Promise<ClientReportingProgram | null>

  const loadClientReportingPrograms = LoadClientReportingPrograms({
    RestService,
    AccountService,
    HandleError,
  }) as (
    params: LoadClientReportingProgramsParams,
  ) => Promise<ClientReportingProgram[]>

  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  const loadDinerProfiles = LoadDinerProfiles({
    RestService,
    HandleError,
  })

  const closeModal = CloseEditClientReportingProgramModal({
    AccountService,
    UIService,
  })

  const flashError = (message: string) =>
    UIService.FlashMessage.displayFailureMessage(message)

  return {
    saveClientReportingProgram,
    loadClientReportingPrograms,
    loadHeadquarters,
    loadDinerProfiles,
    closeModal,
    flashError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditReportingProgramModal)
