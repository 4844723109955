import { SET_CLIENT_REPORTING_PROGRAMS } from '@actions/clientReportingPrograms'

import { ClientReportingProgram } from '@types'
import { AnyAction } from 'redux'

const initialState: ClientReportingProgram[] = []

export const clientReportingPrograms = (
  state = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_CLIENT_REPORTING_PROGRAMS:
      return action.payload
    default:
      return state
  }
}
