export const DISPLAY_INFO_MODAL = 'DISPLAY_INFO_MODAL'
export const DISMISS_INFO_MODAL = 'DISMISS_INFO_MODAL'

export const displayInfoModal = ({ title, content }) => {
  return {
    type: DISPLAY_INFO_MODAL,
    title,
    content,
  }
}

export const dismissInfoModal = () => {
  return {
    type: DISMISS_INFO_MODAL,
  }
}
