import { connect } from 'react-redux'

import { EditDinerProfileModal } from '@components/account/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'

import { DinerProfile, ClientReportingProgram } from '@types'

interface State {
  account: any
  clientReportingPrograms: ClientReportingProgram[]
  editDinerProfile: DinerProfile
  user: any
}

const mapStateToProps = (state: State) => {
  const {
    account,
    editDinerProfile: dinerProfile,
    clientReportingPrograms,
    user,
  } = state

  return {
    account,
    clientReportingPrograms,
    dinerProfile: {
      ...dinerProfile,
      hqId: user.lastLoggedInHq,
      accountId: account.id,
    },
    user,
  }
}

const mapDispatchToProps = () => {
  const {
    RestService,
    AccountService,
    MarketService,
    UIService,
    SettingsService,
  } = services
  const {
    SaveDinerProfile,
    DeleteDinerProfile,
    CloseEditDinerProfileModal,
    LoadMarkets,
    LoadDinerProfiles,
    LoadAccountDinerProfiles,
    AsyncLoadOrderSettings,
    LoadSettings,
    RetryOnTimeout,
  } = coordinators
  const {
    pResponseHeadquarters,
    pRequestSettings,
    pResponseSettings,
    pRequestDinerProfile,
    pResponseDinerProfiles,
    pResponseCustomOrderSettings,
  } = presenters.Api
  const { pOrderSettings } = presenters

  const saveDinerProfile = SaveDinerProfile({
    RestService,
    AccountService,
    UIService,
    HandleError,
    pRequestDinerProfile,
  }) as (profile: DinerProfile) => Promise<DinerProfile | null>

  const deleteDinerProfile = DeleteDinerProfile({
    RestService,
    AccountService,
    UIService,
    HandleError,
  })

  const closeModal = CloseEditDinerProfileModal({ AccountService, UIService })

  const loadHeadquarters = LoadMarkets({
    MarketService,
    RestService,
    pResponseHeadquarters,
  })

  const loadDinerProfiles = LoadDinerProfiles({ RestService, HandleError })
  const loadAccountDinerProfiles = LoadAccountDinerProfiles({
    RestService,
    AccountService,
    HandleError,
    pResponseDinerProfiles,
  })

  const loadOrderSettings = AsyncLoadOrderSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    pOrderSettings,
    LoadSettings,
    RetryOnTimeout,
    HandleError,
  })

  const loadConceptsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('concepts')

  const loadMenuItemSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('menuItems')

  const flashError = (message: string) =>
    UIService.FlashMessage.displayFailureMessage(message)

  const pCustomOrderSettings = pResponseCustomOrderSettings

  return {
    saveDinerProfile,
    deleteDinerProfile,
    closeModal,
    loadHeadquarters,
    loadDinerProfiles,
    loadAccountDinerProfiles,
    loadOrderSettings,
    loadConceptsSettings,
    loadMenuItemSettings,
    flashError,
    pCustomOrderSettings,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDinerProfileModal)
