import { camelCaseify, snakeCaseify } from '../../../utils'

export const GetChatHistory =
  ({ AiApiService }) =>
  async (chatId) => {
    const response = await AiApiService.get(
      `/catering-concierge/chat-history`,
      { chat_id: chatId },
    )

    return camelCaseify(response.data).chatHistory
  }

export const GetUserChats =
  ({ AiApiService }) =>
  async (userId) => {
    const response = await AiApiService.get(`/catering-concierge/user-chats`, {
      user_id: userId,
    })

    return camelCaseify(response.data).userChats
  }

export const CreateUserChat =
  ({ AiApiService, HandleError }) =>
  async ({ userId, llmModel, temperature, topP, maxTokens, chatText }) => {
    try {
      const response = await AiApiService.post(`/catering-concierge/new-chat`, {
        user_id: userId,
        llm_model: llmModel,
        temperature,
        top_logprobs: topP,
        max_tokens: maxTokens,
        chat_text: chatText,
      })

      return camelCaseify(response.data)
    } catch (error) {
      HandleError({ error })
    }
  }

export const EditUserChat =
  ({ AiApiService, HandleError }) =>
  async ({ userId, userChatId, title, isArchived }) => {
    const req = { userId, userChatId }
    if (title) {
      req.title = title
    }
    if (isArchived) {
      req.isArchived = isArchived
    }

    try {
      const response = await AiApiService.put(
        `/catering-concierge/chat`,
        snakeCaseify(req),
      )

      return camelCaseify(response.data).userChat
    } catch (error) {
      HandleError({ error })
    }
  }

export const AddUserChatMessage =
  ({ AiApiService, HandleError }) =>
  async ({
    userId,
    userChatId,
    llmModel,
    temperature,
    topP,
    maxTokens,
    chatText,
  }) => {
    try {
      const response = await AiApiService.post(
        `/catering-concierge/add-chat-message`,
        {
          user_id: userId,
          user_chat_id: userChatId,
          llm_model: llmModel,
          temperature,
          top_logprobs: topP,
          max_tokens: maxTokens,
          chat_text: chatText,
        },
      )

      return camelCaseify(response.data).chatMessages
    } catch (error) {
      HandleError({ error })
    }
  }
