export enum ChatRole {
  User = 'user',
  System = 'system',
  Assistant = 'assistant',
  Tool = 'tool',
}

export interface UserChat {
  id: number
  userId: string
  createdAt: string
  lastChatAt: string
  chatId: string
  isArchived: boolean
  title: string
}

export interface Chat {
  additionalKwargs?: ChatKwargs
  blocks: ChatBlock[]
  role: ChatRole
}

export interface ChatKwargs {
  name?: string
  toolCallId?: string
  toolCalls?: ChatToolCall[]
}

export interface ChatBlock {
  blockType: string
  text: string
  role: string
}

export interface ChatToolCall {
  id: string
  type: string
  function: ChatToolCallFunc
}

export interface ChatToolCallFunc {
  arguments: string
  name: string
}
