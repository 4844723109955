import React, { useState } from 'react'
import NumberFormat from 'react-number-format'

interface RangeSliderProps {
  label: string
  min: number
  max: number
  step: number
  initialValue: number
  onChange: (value: number) => void
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  label,
  min,
  max,
  step,
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState<number>(initialValue)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = Number(e.target.value)
    setValue(nextValue)
    onChange(nextValue)
  }

  const handleNumberInputChange = (values: { value: string }) => {
    const numValue = Number(values.value)
    if (numValue >= min && numValue <= max) {
      setValue(numValue)
      onChange(numValue)
    }
  }

  const handleReset = () => {
    setValue(initialValue)
    onChange(initialValue)
  }

  const sliderPercentage = ((value - min) / (max - min)) * 100

  return (
    <div className="flex flex-col items-start space-y-2">
      <label htmlFor="rangeSlider" className="text-xs font-bold uppercase">
        {label}
      </label>
      <input
        id="rangeSlider"
        type="range"
        className="w-full h-2 rounded-full appearance-none focus:outline-none"
        min={min}
        max={max}
        step={step}
        value={value}
        onInput={handleInputChange}
        style={{
          background: `linear-gradient(to right, #84898b ${sliderPercentage}%, #D1D5DB ${sliderPercentage}%)`,
        }}
      />
      <div className="flex items-center space-x-2">
        <NumberFormat
          value={value}
          onValueChange={handleNumberInputChange}
          className="text-sm font-bold p-1 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
          isNumericString
          allowNegative={false}
          decimalScale={Number.isInteger(step) ? 0 : undefined} // Allow floats only if step is not an integer
        />
        <button
          onClick={handleReset}
          className="p-1 text-gray-500 hover:text-blue-500 focus:outline-none focus:ring focus:ring-blue-300 rounded"
          aria-label="Reset to default value"
          title="Reset to default value"
        >
          &#x21BB;
        </button>
      </div>
    </div>
  )
}

export default RangeSlider
