import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { LabelInfo } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import { PanelListItem } from '@res/styledComponents/index'

import { moneyString } from '~/utils'
import { NetPaymentDays, AutoBatchPeriods, colors } from '../../../../constants'

const AccountProfileListGroup = (props) => {
  const renderableAddresses = useMemo(() => {
    const { addresses } = props.accountProfile || {}

    return (addresses || []).filter((address) => address.fullAddress.length > 0)
  }, [props.accountProfile])

  if (!props.accountProfile) {
    return null
  }

  const {
    accountContacts,
    budgetPerPerson,
    callOnArrival,
    contacts,
    estimatedMonthlyValue,
    isTaxExempt,
    parentAccount,
    name,
    needsServingUtensils,
    needsUtensils,
    numEmployees,
    pin,
    netPaymentDays,
    autoBatchPeriod,
    vendorId,
    totalMealsDonated,
    totalCarbonNeutralContribution,
    treesPlanted,
    dinerProfiles,
    clientReportingPrograms,
  } = props.accountProfile

  const {
    onEditAccount,
    onEditClientReportingProgram,
    onEditDinerProfile,
    onDeleteDinerProfile,
    onMarkDefaultDinerProfile,
    onMarkDefaultContact,
    onMarkDefaultAddress,
    onDeleteAccountContact,
    onDeleteAccountAddress,
  } = props

  const parentAccountName = parentAccount && parentAccount.name

  const renderContactInfo = ({ id, formattedDescription }) => {
    const accountContact = accountContacts.find((c) => c.contactId === id)
    const isDefault = accountContact && accountContact.isDefault

    const parts = formattedDescription.split(' - ')

    return parts.map((part, index) => (
      <>
        <p key={index} className={index === 0 ? 'font-semibold' : ''}>
          {part}
          {index === 0 && isDefault && (
            <span className="font-normal"> (Default)</span>
          )}
        </p>
        {index < parts.length - 1 && <p className="mx-2">-</p>}
      </>
    ))
  }

  return (
    <FlexContainer flexDirection="row" width="100%" maxHeight="1000px">
      <FlexContainer
        flexDirection="column"
        width="50%"
        minWidth="500px"
        maxHeight="1000px"
        overflow="scroll"
      >
        <Panel width="100%" maxWidth="400px" heading="Account Details">
          <FlexContainer justifyContent="space-between">
            <LabelInfo label="Account Name" value={name} width="48%" />
            <LabelInfo label="Account Pin" value={pin} width="48%" />
          </FlexContainer>
          <YSpacing height="20px" />
          {parentAccountName && (
            <LabelInfo
              label="Parent Account Name"
              value={parentAccountName}
              width="100%"
              testId="parent-account"
            />
          )}
          {parentAccountName && <YSpacing height="20px" />}
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Tax Exempt"
              testId="vendor-id"
              value={isTaxExempt ? 'Yes' : 'No'}
              width="48%"
            />
            <LabelInfo
              label="Call On Arrival"
              testId="call-on-arrival"
              value={callOnArrival ? 'Yes' : 'No'}
              width="48%"
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="# of Employees"
              testId="number-of-employees"
              value={numEmployees || 'N/A'}
              width="48%"
            />
            <LabelInfo
              label="Budget Per Person (excluding tax)"
              testId="budget-per-person"
              value={`$${moneyString(Number(budgetPerPerson))}`}
              width="48%"
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Needs Utensils"
              value={needsUtensils ? 'Yes' : 'No'}
              testId="needs-utensils"
              width="48%"
            />
            <LabelInfo
              label="Needs Utensils, Napkins, & Plates"
              value={needsServingUtensils ? 'Yes' : 'No'}
              testId="needs-utensils-and-napkins"
              width="48%"
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Estimated Monthly Value"
              value={`$${moneyString(Number(estimatedMonthlyValue))}`}
              testId="estimated-monthly-value"
              width="48%"
            />
            <LabelInfo
              label="Total # of Meals Donated"
              value={totalMealsDonated}
              testId="total-meals-donated"
              width="48%"
            />
            {vendorId && (
              <LabelInfo
                label="Vendor ID"
                testId="vendor-id"
                value={vendorId}
                width="48%"
              />
            )}
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Total $ Contributed to Carbon Neutral"
              value={`$${moneyString(
                Number(totalCarbonNeutralContribution || 0),
              )}`}
              testId="total-carbon-neutral-contributed"
              width="48%"
            />
            <LabelInfo
              label="Total # of Trees Planted"
              value={`${treesPlanted || 0}`}
              testId="total-trees-planted"
              width="48%"
            />
          </FlexContainer>
        </Panel>
        <Panel
          width="100%"
          maxWidth="400px"
          heading="Net & Recurring Payment Details"
        >
          <FlexContainer justifyContent="space-between">
            <LabelInfo
              label="Net Payment Terms"
              value={NetPaymentDays[netPaymentDays]}
              width="23%"
            />
            <LabelInfo
              label="Invoicing Cadence"
              value={AutoBatchPeriods[autoBatchPeriod]}
              width="73%"
            />
          </FlexContainer>
        </Panel>
      </FlexContainer>
      <FlexContainer
        flexDirection="column"
        width="50%"
        maxHeight="1000px"
        minWidth="600px"
        overflow="scroll"
      >
        <Panel
          width="100%"
          maxWidth="600px"
          heading="Addresses"
          subHeading="Last Modified By"
        >
          {renderableAddresses.map((a, i) => (
            <PanelListItem
              isLast={i === renderableAddresses.length - 1}
              id="addresses"
              key={a.id}
            >
              <div className="flex flex-row justify-between w-full">
                <div className="w-3/4">
                  <div>
                    <p className="font-semibold">
                      {a.line1}
                      {a.line2 !== '' ? `, ${a.line2}` : ''}
                      {a.isDefault && (
                        <span className="font-normal"> (Default)</span>
                      )}
                    </p>
                    <p className="font-normal">
                      {a.city}, {a.state} {a.zip}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between mt-3">
                    <button
                      className="text-green-500 underline"
                      onClick={onEditAccount}
                    >
                      Edit Address
                    </button>
                    <button
                      className="text-green-500 underline"
                      onClick={() => onMarkDefaultAddress(a.id)}
                    >
                      Mark Default
                    </button>
                    <button
                      className="text-green-500 underline"
                      onClick={() => onDeleteAccountAddress(a)}
                    >
                      Remove From Client Profile
                    </button>
                  </div>
                </div>
                <p
                  className="w-1/4 text-right ml-5"
                  style={{ borderLeft: `1px solid ${colors.gray200}` }}
                >
                  {a.updatedBy || 'Legacy Updated'} <br></br>{' '}
                  {Moment(a.updatedAt).format('MM/DD/YYYY')}
                </p>
              </div>
            </PanelListItem>
          ))}
        </Panel>
        <Panel
          width="100%"
          maxWidth="600px"
          heading="Contacts"
          subHeading="Last Modified By"
        >
          {contacts &&
            contacts
              .sort((a, b) => {
                const aUpdated = Moment(a.updatedAt).format('YYYY-MM-DD')
                const bUpdated = Moment(b.updatedAt).format('YYYY-MM-DD')

                if (aUpdated < bUpdated) {
                  return 1
                } else if (aUpdated > bUpdated) {
                  return -1
                } else {
                  return (a.firstName || '').localeCompare(b.firstName || '')
                }
              })
              .map(
                (c, i) =>
                  c.formattedDescription.length > 0 && (
                    <PanelListItem
                      isLast={i === contacts.length - 1}
                      id="contacts"
                      key={c.id}
                    >
                      <div className="flex flex-row justify-between w-full">
                        <div className="w-3/4">
                          <div className="flex flex-row">
                            {renderContactInfo(c)}
                          </div>
                          <div className="flex flex-row justify-between mt-3">
                            <button
                              className="text-green-500 underline"
                              onClick={onEditAccount}
                            >
                              Edit Contact
                            </button>
                            <button
                              className="text-green-500 underline"
                              onClick={() => onMarkDefaultContact(c.id)}
                            >
                              Mark Default
                            </button>
                            <button
                              className="text-green-500 underline"
                              onClick={() =>
                                onDeleteAccountContact(
                                  accountContacts.find(
                                    (accContact) =>
                                      accContact.contactId === c.id,
                                  ),
                                )
                              }
                            >
                              Remove From Client Profile
                            </button>
                          </div>
                        </div>
                        <p
                          className="w-1/4 text-right ml-5"
                          style={{ borderLeft: `1px solid ${colors.gray200}` }}
                        >
                          {c.updatedBy || 'Legacy Updated'} <br></br>{' '}
                          {Moment(c.updatedAt).format('MM/DD/YYYY')}
                        </p>
                      </div>
                    </PanelListItem>
                  ),
              )}
        </Panel>
        <Panel
          width="100%"
          maxWidth="600px"
          heading="Linked Diner Profiles"
          subHeading="Last Modified By"
        >
          {dinerProfiles &&
            dinerProfiles.map((profile, i) => (
              <PanelListItem
                id="dinerProfiles"
                key={profile.id}
                isLast={i === dinerProfiles.length - 1}
              >
                <div className="flex flex-row justify-between w-full">
                  <div className="w-3/4">
                    <p className="font-semibold">
                      {profile.name}
                      {profile.isDefault && (
                        <span className="font-normal"> (Default)</span>
                      )}
                    </p>
                    <div className="flex flex-row justify-between mt-3">
                      <button
                        className="text-green-500 underline"
                        onClick={() => onEditDinerProfile(profile)}
                      >
                        Edit Diner Profile
                      </button>
                      <button
                        className="text-green-500 underline"
                        onClick={() => onMarkDefaultDinerProfile(profile.id)}
                      >
                        Mark Default
                      </button>
                      <button
                        className="text-green-500 underline"
                        onClick={() => onDeleteDinerProfile(profile)}
                      >
                        Remove From Client Profile
                      </button>
                    </div>
                  </div>
                  <p
                    className="w-1/4 text-right ml-5"
                    style={{ borderLeft: `1px solid ${colors.gray200}` }}
                  >
                    {profile.lastUpdatedBy} <br></br>{' '}
                    {Moment(profile.updatedAt).format('MM/DD/YYYY')}
                  </p>
                </div>
              </PanelListItem>
            ))}
        </Panel>
        <Panel
          width="100%"
          maxWidth="600px"
          heading="Reporting Programs"
          subHeading="Last Modified By"
        >
          {clientReportingPrograms &&
            clientReportingPrograms.map((program, i) => (
              <PanelListItem
                id="clientReportingPrograms"
                key={program.id}
                isLast={i === clientReportingPrograms.length - 1}
              >
                <div className="flex flex-row justify-between w-full">
                  <div className="w-3/4">
                    <p className="font-semibold">
                      {program.name}
                      {program.isDefault && (
                        <span className="font-normal"> (Default)</span>
                      )}
                    </p>
                    <div className="flex flex-row justify-between mt-3">
                      <button
                        className="text-green-500 underline"
                        onClick={() => onEditClientReportingProgram(program)}
                      >
                        Edit Reporting Program
                      </button>
                    </div>
                  </div>
                  <p
                    className="w-1/4 text-right ml-5"
                    style={{ borderLeft: `1px solid ${colors.gray200}` }}
                  >
                    {program.lastUpdatedBy || 'N/A'} <br></br>{' '}
                    {Moment(program.updatedAt).format('MM/DD/YYYY')}
                  </p>
                </div>
              </PanelListItem>
            ))}
        </Panel>
      </FlexContainer>
    </FlexContainer>
  )
}

AccountProfileListGroup.propTypes = {
  accountProfile: PropTypes.object,

  onEditAccount: PropTypes.func,
  onEditClientReportingProgram: PropTypes.func,
  onEditDinerProfile: PropTypes.func,
  onDeleteDinerProfile: PropTypes.func,
  onMarkDefaultDinerProfile: PropTypes.func,
  onMarkDefaultContact: PropTypes.func,
  onMarkDefaultAddress: PropTypes.func,
  onDeleteAccountContact: PropTypes.func,
  onDeleteAccountAddress: PropTypes.func,
}

AccountProfileListGroup.defaultProps = {
  accountProfile: {},
}

export default AccountProfileListGroup
